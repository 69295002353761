import { Directus } from "@directus/sdk";

// const config = {
//   authOptions: {
//     mode: "json", // 'json' in Node.js
//     autoRefresh: true,
//     msRefreshBeforeExpires: 30000,
//     staticToken: "",
//   },
//   storage: {
//     prefix: "",
//     mode: "MemoryStorage", // 'MemoryStorage' in Node.js
//   },
//   transport: {
//     params: {},
//     headers: {},
//     onUploadProgress: (ProgressEvent) => {},
//     maxBodyLength: null,
//     maxContentLength: null,
//   },
// };

export const directus = new Directus(
  "https://directus.botcalculator.com"
  // config
);
