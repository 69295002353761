import { Auth, Directus, TypeMap } from "@directus/sdk";
import { Dispatch, SetStateAction } from "react";
import { GraphRecord, TableRecord } from "../utils/types";
import { toast } from "react-toastify";

export const getBacktestData = async (
  setData: Dispatch<SetStateAction<any[]>>,
  path: string
) => {
  await fetch(
    `https://api.botcalculator.com/hb/v1/stats/public/back_test/${path}`
  ).then(async (res) => {
    if (res.ok) {
      const json = await res.json();
      setData(json);

      console.log(json);
    } else {
      toast("HTTP-Error: " + res.status, { type: "error" });
    }
  });
};

export const getDataGraphs = async (
  setData: Dispatch<SetStateAction<GraphRecord[]>>,
  path: string
) => {
  await fetch(`https://api.botcalculator.com/v3/table_data/${path}`).then(
    async (res) => {
      if (res.ok) {
        const json = await res.json();
        setData(json);
      } else {
        toast("HTTP-Error: " + res.status, { type: "error" });
      }
    }
  );
};

export const getDataTables = async (
  setTableData: Dispatch<SetStateAction<TableRecord[]>>,
  path: string
) => {
  await fetch(`https://api.botcalculator.com/v3/current_data/${path}`).then(
    async (res) => {
      if (res.ok) {
        const json = await res.json();
        json.sort(function (a: TableRecord, b: TableRecord) {
          return a.Symbol.localeCompare(b.Symbol);
        });
        setTableData(json);
      } else {
        toast("HTTP-Error: " + res.status, { type: "error" });
      }
    }
  );
};

export const getUserTables = async (
  directus: Directus<TypeMap, Auth>,
  setTableData: Dispatch<SetStateAction<TableRecord[]>>,
  id: string
) => {
  const token = await directus.auth.token;

  await fetch(
    `https://api.botcalculator.com/v1/user/current/stats?user_id=${id}`,
    {
      headers: {
        AuthToken: token || "",
      },
    }
  ).then(async (res) => {
    if (res.ok) {
      const json = await res.json();
      setTableData(json.Data);
    } else {
      toast("HTTP-Error: " + res.status, { type: "error" });
    }
  });
};

export const getUserGraphs = async (
  directus: Directus<TypeMap, Auth>,
  setData: Dispatch<SetStateAction<GraphRecord[]>>,
  id: string
) => {
  const token = await directus.auth.token;

  await fetch(
    `https://api.botcalculator.com/v1/user/current/graphs?user_id=${id}`,
    {
      headers: {
        AuthToken: token || "",
      },
    }
  ).then(async (res) => {
    if (res.ok) {
      const json = await res.json();
      setData(json.Data);
    } else {
      toast("HTTP-Error: " + res.status, { type: "error" });
    }
  });
};

export const getUserApi = async (
  directus: Directus<TypeMap, Auth>,
  id: string
) => {
  const token = await directus.auth.token;

  await fetch(`https://api.botcalculator.com/v1/user/api?user_id=${id}`, {
    headers: {
      AuthToken: token || "",
    },
  }).then(async (res) => {
    if (res.ok) {
      const json = await res.json();
      console.log(json);
      return json;
    } else {
      toast("HTTP-Error: " + res.status, { type: "error" });
    }
  });
};

export const updateUserApi = async (
  directus: Directus<TypeMap, Auth>,
  body: any
) => {
  const token = await directus.auth.token;

  await fetch("https://api.botcalculator.com/v1/user/api", {
    method: "POST",
    headers: {
      AuthToken: token || "",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then(async (res) => {
    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      toast("HTTP-Error: " + res.status, { type: "error" });
    }
  });
};
