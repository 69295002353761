import { useQuery } from "@tanstack/react-query";
import { directus } from "../directus";

export const useApiKeys = () =>
  useQuery({
    queryKey: ["apiKeys", directus.auth.token],
    queryFn: async () => {
      await directus.auth.refreshIfExpired();
      const token = await directus.auth.token;

      const res = await fetch(`https://api.botcalculator.com/v1/user/api`, {
        headers: {
          AuthToken: token || "",
        },
      });
      const json = await res.json();

      return json;
    },
  });
