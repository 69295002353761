import { Text, FormControl, Select, SelectProps } from "@chakra-ui/react";
import { ReactComponent as EditSquareIcon } from "../../icons/edit_square.svg";

type Props = {
  name: string;
  errors: any;
  touched: any;
  handleChange: any;
  placeholder: string;
  values: any;
  options: any[];
  onSelect?: (val: any) => void;
  optionLabel: (option: any) => string;
  optionValue: (option: any) => string;
} & SelectProps;

const FormSelect = ({
  name,
  errors,
  touched,
  handleChange,
  placeholder,
  values,
  options,
  onSelect,
  optionLabel,
  optionValue,
  ...props
}: Props) => {
  return (
    <FormControl mr={2}>
      <Select
        id={name}
        name={name}
        placeholder={placeholder}
        style={{
          width: "100%",
          color: "white",
          background: "#14142A",
          border: "1px solid #6E7191",
          borderRadius: "5px",
          height: "40px",
          padding: "0 16px",
        }}
        _placeholder={{ background: "#262338" }}
        onChange={(e) => {
          handleChange(e);
          onSelect?.(e.target.value);
        }}
        value={values?.[name]}
        {...props}
      >
        {options.map((option) => (
          <option
            style={{
              width: "100%",
              background: "#262338",
              maxHeight: "100px",
              overflow: "auto",
              position: "absolute",
              top: 68,
              left: 0,
              zIndex: 999,
              padding: "8px 16px",
              marginRight: "8px",
            }}
            value={optionValue(option)}
          >
            {optionLabel(option)}
          </option>
        ))}
      </Select>
      {errors?.[name] && touched?.[name] ? (
        <Text color="#9E0038">{errors?.[name]}</Text>
      ) : null}
    </FormControl>
  );
};

export default FormSelect;
