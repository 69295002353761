import { useQuery } from "@tanstack/react-query";
import { directus } from "../directus";

export const useDcaInstances = () =>
  useQuery({
    queryKey: ["instances", directus.auth.token],
    queryFn: async () => {
      await directus.auth.refreshIfExpired();
      const token = await directus.auth.token;

      const res = await fetch(
        "https://api.botcalculator.com/v1/dca/dca_instance/list",
        {
          headers: {
            AuthToken: token || "",
          },
        }
      );

      const json = await res.json();

      return json;
    },
  });
