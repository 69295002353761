import { Flex, FlexProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

const Section = ({
  children,
  disabled,
  ...props
}: PropsWithChildren & FlexProps & { disabled?: boolean }) => {
  return (
    <Flex w="100%" position="relative">
      {disabled && (
        <Flex
          position="absolute"
          w="full"
          h="full"
          zIndex={1000}
          cursor={disabled ? "not-allowed" : "pointer"}
        />
      )}

      <Flex
        flexDir="column"
        alignItems="flex-start"
        borderWidth={1}
        borderColor="#6E7191"
        bg="bg"
        gap={4}
        p={4}
        w="100%"
        borderRadius={15}
        opacity={disabled ? 0.5 : 1}
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default Section;
