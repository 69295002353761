import {
  Flex,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import Island from "../components/Island";
import { FaRegEdit } from "react-icons/fa";

import { ReactComponent as CloudIcon } from "../icons/cloud.svg";
import { ReactComponent as SwapIcon } from "../icons/swap_horiz.svg";
import { ReactComponent as WalletIcon } from "../icons/account_balance_wallet.svg";
import { ReactComponent as TimerIcon } from "../icons/av_timer.svg";
import { ReactComponent as HistoryIcon } from "../icons/history.svg";
import { ReactComponent as ForwardIcon } from "../icons/forward_circle.svg";
import { ReactComponent as StopIcon } from "../icons/stop.svg";
import { ReactComponent as PlayIcon } from "../icons/play_arrow.svg";
import { ReactComponent as PauseIcon } from "../icons/pause.svg";
import { ReactComponent as ArrowsUp } from "../icons/arrows_more_up.svg";
import { ReactComponent as ArrowsDown } from "../icons/more_down.svg";

import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { useDcaDetail } from "../api/query/useDcaDetail";
import { formatDateDcaDetail } from "../utils/date";
import Select from "../components/Select";
import { useDcaStateUpdate } from "../api/mutations/useDcaStateUpdate";
import { useTransactions } from "../api/query/useTransactions";
import { useCount } from "../api/query/useCount";

const DcaDetail = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSaving, setIsSaving] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { mutateAsync } = useDcaStateUpdate();

  const [selected, setSelected] = useState("");

  const id = useMemo(() => pathname.split("/")[2], [pathname]);

  const { data, isLoading, refetch } = useDcaDetail(id);
  const { data: transactions, isLoading: areTransactionsLoading } =
    useTransactions(data?.Data?.Config?.InstanceId, showTransactions);
  const { data: count, isLoading: isCountLoading } = useCount(
    data?.Data?.Config?.InstanceId
  );

  console.log(data);

  const timeUnitValues = [
    {
      value: "HOURS",
      label: t("dca:hours"),
    },
    {
      value: "DAYS",
      label: t("dca:days"),
    },
    {
      value: "WEEKS",
      label: t("dca:weeks"),
    },
    {
      value: "MONTHS",
      label: t("dca:months"),
    },
    {
      value: "MINUTES",
      label: t("dca:minutes"),
    },
  ];

  const stateTransform = (state: string) => {
    switch (state) {
      case "ACTIVE":
        return (
          <Text color="#A6F787" display="flex" alignItems="center" gap={3}>
            <PlayIcon fill="#A6F787" /> {t("dca:active")}
          </Text>
        );
      case "INSUFFICIENT_BALANCE":
        return (
          <Text color="#FFA65A" display="flex" alignItems="center" gap={3}>
            <StopIcon fill="#FFA65A" /> {t("dca:insufficient_balance")}
          </Text>
        );
      case "STOPPED":
        return (
          <Text color="#FF75CB" display="flex" alignItems="center" gap={3}>
            <PauseIcon fill="#FF75CB" /> {t("dca:stopped")}
          </Text>
        );
      case "LIMIT_EXCEEDED":
        return (
          <Text color="#FFA65A" display="flex" alignItems="center" gap={3}>
            <StopIcon fill="#FFA65A" /> {t("dca:limit_exceeded")}
          </Text>
        );
    }
  };

  const typeTransform = (type: string) => {
    switch (type) {
      case "DCA_MARKET_BUY":
        return (
          <Text
            color="#B374FF"
            display="flex"
            alignItems="center"
            border="1px solid #B374FF"
            p={1}
            w="min"
            borderRadius={5}
            fontSize={11}
          >
            {t("dca_detail:buy")}
          </Text>
        );
      case "DCA_MARKET_SELL":
        return (
          <Text
            color="#FF75CB"
            display="flex"
            alignItems="center"
            border="1px solid #FF75CB"
            p={1}
            w="min"
            borderRadius={5}
            fontSize={11}
          >
            {t("dca_detail:sell")}
          </Text>
        );
    }
  };

  if (isLoading || isCountLoading) return <div>loading...</div>;

  return (
    <>
      <Island
        p={8}
        flexDir="row"
        justifyContent="space-between"
        display={{ base: "none", sm: "flex" }}
      >
        <Flex gap={4} alignItems="center">
          <Text fontSize={32}>
            {data?.Data?.Config?.Name} {data?.Data?.Config.Ticker}
          </Text>
        </Flex>

        <Flex gap={4} alignItems="end">
          <Select
            current={data?.Data?.Config?.Status}
            onSelect={(val: string) => {
              setSelected(val);
              onOpen();
            }}
          />
          <Button
            bg="island"
            color="brand"
            fontSize={16}
            fontWeight={600}
            border="1px solid #6E7191"
            onClick={() => navigate("edit")}
            display="flex"
            gap={2}
            alignItems="center"
          >
            Upravit
            <FaRegEdit />
          </Button>
        </Flex>
      </Island>
      <Island
        p={6}
        flexDir="row"
        justifyContent="space-between"
        gap={8}
        display={{ base: "none", sm: "flex" }}
        flexWrap="wrap"
      >
        <Flex flexDir="column">
          <Text fontSize="24px">{t("dca_detail:title")}</Text>

          <Flex gap={2} fontSize={16} mt={4} alignItems="center">
            <CloudIcon fill="#6E7191" />
            <Flex flexDir="column">
              <Text fontSize={12} color="#D9DBE9">
                {t("dca_detail:api_key")}{" "}
              </Text>
              <Text fontSize={14} color="#EFF0F6">
                {data.Data.Config.ApiId.Name}
              </Text>
            </Flex>
          </Flex>

          <Flex gap={2} fontSize={16} mt={4} alignItems="center">
            <SwapIcon fill="#6E7191" />
            <Flex flexDir="column">
              <Text fontSize={12} color="#D9DBE9">
                {t("dca_detail:pair")}{" "}
              </Text>
              <Text fontSize={14} color="#EFF0F6">
                {data.Data.Config.Ticker}
              </Text>
            </Flex>
          </Flex>

          <Flex gap={2} fontSize={16} mt={4} alignItems="center">
            <WalletIcon fill="#6E7191" />
            <Flex flexDir="column">
              <Text fontSize={12} color="#D9DBE9">
                {t("dca_detail:amount")}{" "}
              </Text>
              <Text fontSize={14} color="#EFF0F6">
                {data.Data.Config.PriceUnit} $
              </Text>
            </Flex>
          </Flex>

          <Flex gap={2} fontSize={16} mt={4} alignItems="center">
            <TimerIcon fill="#6E7191" />
            <Flex flexDir="column">
              <Text fontSize={12} color="#D9DBE9">
                {t("dca_detail:frequency")}{" "}
              </Text>
              <Text fontSize={14} color="#EFF0F6">
                {data.Data.Config.Frequency}{" "}
                {
                  timeUnitValues.find(
                    (item) => item.value === data.Data.Config.TimeUnit
                  )?.label
                }
              </Text>
            </Flex>
          </Flex>

          <Flex gap={2} fontSize={16} mt={4} alignItems="center">
            <HistoryIcon fill="#6E7191" />
            <Flex flexDir="column">
              <Text fontSize={12} color="#D9DBE9">
                {t("dca_detail:last_buy")}{" "}
              </Text>
              <Text fontSize={14} color="#EFF0F6">
                {formatDateDcaDetail(data.Data.LastActionTime)}
              </Text>
            </Flex>
          </Flex>

          <Flex gap={2} fontSize={16} mt={4} alignItems="center">
            <ForwardIcon fill="#6E7191" />
            <Flex flexDir="column">
              <Text fontSize={12} color="#D9DBE9">
                {t("dca_detail:next_buy")}{" "}
              </Text>
              <Text fontSize={14} color="#EFF0F6">
                {formatDateDcaDetail(data.Data.NextActionTime)}
              </Text>
            </Flex>
          </Flex>

          {data.Data.under_average_options && (
            <Flex gap={2} fontSize={16} mt={4} alignItems="start">
              <ArrowsUp fill="#6E7191" />
              <Flex flexDir="column">
                <Text fontSize={12} color="#D9DBE9">
                  {t("dca_detail:special_buys")}{" "}
                </Text>

                {data.Data.under_average_options ? (
                  data.Data.under_average_options?.map((item: any) => (
                    <Text fontSize={14} color="#EFF0F6">
                      {t("dca_detail:special", {
                        percentage: item.below_avg_pct,
                        value: item.value,
                        sign:
                          item?.type === "RATIO"
                            ? "%"
                            : data.Data?.Config?.Ticker.split("-")[1],
                      })}
                    </Text>
                  ))
                ) : (
                  <Text fontSize={14} color="#EFF0F6">
                    -
                  </Text>
                )}
              </Flex>
            </Flex>
          )}

          {data?.Data?.price_limit && (
            <Flex gap={2} fontSize={16} mt={4} alignItems="center">
              <ArrowsDown fill="#6E7191" />
              <Flex flexDir="column">
                <Text fontSize={12} color="#D9DBE9">
                  {t("dca_detail:price_border")}{" "}
                </Text>
                <Text fontSize={14} color="#EFF0F6">
                  {t("dca_detail:price_limit", {
                    limit: data?.Data?.price_limit?.limit,
                    ratio: data?.Data?.price_limit?.ratio,
                  })}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent
            border="1px solid #6E7191"
            borderRadius={15}
            minWidth="fit-content"
          >
            <ModalHeader color="white" fontWeight={400}>
              {t("dca_detail:modal_title")}
            </ModalHeader>
            <ModalBody color="white" display="flex" gap={3} whiteSpace="nowrap">
              {t("dca_detail:modal_text")}
              {stateTransform(data?.Data?.Config?.Status)}
              {t("dca_detail:to")}
              {stateTransform(selected)}
            </ModalBody>

            <ModalFooter display="flex" gap={2}>
              <Button
                variant="ghost"
                onClick={onClose}
                color="brand"
                _hover={{
                  background: "island",
                  border: "1px solid white",
                }}
              >
                {t("dca_detail:cancel")}
              </Button>
              <Button
                bg="brand"
                color="island"
                onClick={async () => {
                  setIsSaving(true);
                  await mutateAsync({
                    api_id: data?.Data?.Config?.InstanceId ?? "",
                    state: selected,
                  });
                  onClose();
                  await refetch();
                  setIsSaving(false);
                }}
                fontSize={16}
                fontWeight={400}
              >
                {isSaving ? <Spinner /> : t("dca_detail:confirm")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Island>

      <Island p={8} flexDir="column" display={{ base: "none", sm: "flex" }}>
        <Flex w="full" justifyContent="space-between" mb={4}>
          <Flex gap={4} alignItems="center">
            <Text fontSize={24}>{t("dca_detail:transactions_history")}</Text>
          </Flex>

          <Flex gap={4} alignItems="end">
            <Flex
              borderWidth={1}
              bg="#262338"
              borderColor="#6E7191"
              p={2}
              borderRadius="10px"
              px={4}
            >
              <Text
                color="#EFF0F6"
                fontSize={16}
                display="flex"
                alignItems="center"
                gap={2}
              >
                {t("dca_detail:number_of_transactions")}:{" "}
                <span className="text-white text-[24px]">
                  {count?.Data ?? 0}
                </span>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {showTransactions ? (
          <Flex w="full" justifyContent="center">
            {areTransactionsLoading ? (
              <Spinner color="white" />
            ) : (
              <Flex flexDir="column" w="full">
                {transactions?.Data?.map((item: any) => (
                  <Flex
                    w="full"
                    flexDir="column"
                    borderBottom="1px solid #6E7191"
                    py={2}
                  >
                    <Flex justifyContent="space-between" w="full">
                      <Text
                        color="#D9DBE9"
                        fontSize={14}
                        display="flex"
                        gap={2}
                        alignItems="center"
                      >
                        {formatDateDcaDetail(item?.created_at)}{" "}
                        {typeTransform(item?.type)}
                      </Text>
                      <Text color="#D9DBE9" fontSize={14}>
                        {t("dca_detail:amount_trans")}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between" w="full">
                      <Text color="#EFF0F6" fontSize={14}>
                        {data?.Data?.Config.Ticker}{" "}
                      </Text>{" "}
                      <Text color="#EFF0F6" fontSize={14}>
                        {item?.amount_fiat}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
        ) : (
          <Button
            bg="brand"
            color="island"
            fontSize={16}
            fontWeight={600}
            onClick={() => setShowTransactions(true)}
          >
            {t("dca_detail:show")}
          </Button>
        )}
      </Island>
    </>
  );
};

export default DcaDetail;
