import {
  FormControl,
  FormLabel,
  Stack,
  Button,
  Spinner,
  Text,
  Container,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordInput from "../components/passwordInput";
import { directus } from "../api/directus";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Island from "../components/Island";

const RegisterSchema = Yup.object().shape({
  token: Yup.string().required("Povinné pole"),
  password: Yup.string().required("Povinné pole"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), undefined], "Hesla se neshodují")
    .required("Povinné pole"),
});

const ResetPassword = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Island>
        <Text fontSize={24} mb={10}>
          Resetovat heslo
        </Text>
        <Formik
          initialValues={{
            token: location.search.split("=")[1],
            password: "",
            confirm_password: "",
          }}
          validationSchema={RegisterSchema}
          onSubmit={async (values) => {
            setIsLoading(true);
            try {
              await directus.auth.password.reset(values.token, values.password);
              toast("Heslo bolo úspešne zmenené", { type: "success" });
              navigate("/");
            } catch (e) {
              const errMsg = (e as any)?.message ?? "Neznámá chyba";
              toast(errMsg, { type: "error" });
            } finally {
              setIsLoading(false);
            }
          }}
        >
          {({ errors, touched, handleChange, values }) => (
            <Form>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="password">Heslo</FormLabel>
                  <PasswordInput
                    id="password"
                    onChange={handleChange}
                    value={values.password}
                    placeholder="Zadejte heslo"
                  />
                  {errors.password && touched.password ? (
                    <Text color="#9E0038">{errors.password}</Text>
                  ) : null}
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="confirm_password">
                    Potvrdit heslo
                  </FormLabel>
                  <PasswordInput
                    id="confirm_password"
                    onChange={handleChange}
                    value={values.confirm_password}
                    placeholder="Potvrďte heslo"
                  />
                  {errors.confirm_password && touched.confirm_password ? (
                    <Text color="#9E0038">{errors.confirm_password}</Text>
                  ) : null}
                </FormControl>

                <Stack spacing={10}>
                  <Button bg="brand" color="island" fontSize={14} type="submit">
                    {isLoading ? <Spinner /> : "Resetovat heslo"}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Island>
    </>
  );
};

export default ResetPassword;
