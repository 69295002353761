import {
  Button,
  Container,
  Heading,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { SessionCtx } from "../context/SessionContext";
import { directus } from "../api/directus";
import Island from "../components/Island";
import Input from "../components/Input";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const { user } = useContext(SessionCtx);

  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Island>
        <Heading as="h1" size="lg" mb={4}>
          Reset Hesla
        </Heading>
        <Text mb={4}>
          {user && user.email
            ? `Pošleme vám odkaz na resetování hesla na ${user.email}`
            : "Zadajte svoju e-mailovú adresu a my vám pošleme odkaz na obnovenie"}
        </Text>

        <Stack spacing={4}>
          {!(user && user.email) && (
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Zadejte email"
            />
          )}

          <Button
            onClick={async () => {
              try {
                setIsLoading(true);
                if (user && user.email) {
                  directus.auth.password.request(user?.email);
                } else {
                  directus.auth.password.request(email);
                }
                toast("Odkaz na reset hesla bol odoslaný", { type: "success" });
              } catch (e) {
                toast("Nepodarilo sa resetovat heslo", { type: "error" });
              } finally {
                setIsLoading(false);
              }
            }}
            bg="brand"
            color="island"
            type="submit"
            fontSize={14}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Resetovat heslo"}
          </Button>
        </Stack>
      </Island>
    </>
  );
};

export default ForgotPassword;
