import { useQuery } from "@tanstack/react-query";
import { directus } from "../directus";

export const useSpotState = (apiId?: string) =>
  useQuery({
    queryKey: ["spotState", apiId, directus.auth.token],
    queryFn: async () => {
      if (!apiId) return null;

      await directus.auth.refreshIfExpired();
      const token = await directus.auth.token;

      const res = await fetch(
        "https://api.botcalculator.com/admin/exchange/spot_state",
        {
          method: "POST",
          headers: {
            AuthToken: token || "",
          },
          body: JSON.stringify({
            api_id: apiId,
          }),
        }
      );

      const json = await res.json();

      return json;
    },
  });
