import {
  Input as ChakraInput,
  InputGroup,
  InputProps,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";

type Props = {
  iconLeft?: any;
  iconRight?: any;
} & InputProps;

const Input = ({ iconLeft, iconRight, ...props }: Props) => {
  return (
    <InputGroup size="md">
      <ChakraInput
        borderColor="#6E7191"
        color="white"
        _autofill={{
          "-webkit-text-fill-color": "white",
          transition: "background-color 9999s ease-in-out 0s",
        }}
        _disabled={{
          color: "white",
        }}
        {...props}
      />
      {iconLeft && <InputLeftElement>{iconLeft}</InputLeftElement>}
      {iconRight && <InputRightElement>{iconRight}</InputRightElement>}
    </InputGroup>
  );
};

export default Input;
