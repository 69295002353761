import {
  Button,
  Flex,
  Heading,
  Text,
  Image,
  Spinner,
  VStack,
  HStack,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { directus } from "../api/directus";

import Island from "../components/Island";
import ReactPlayer from "react-player";
import Multistep from "../components/ApiKeyForm";
import { useTranslation } from "react-i18next";
import { ApiKeysTable } from "../components/ApiKeysTable";

const ApiKeys = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [burza, setBurza] = useState<
    "BINANCE" | "PHEMEX" | "COINMATE" | "BYBIT" | undefined
  >(undefined);

  const [apiKeys, setApiKeys] = useState<any[]>([]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);

    const token = await directus.auth.token;

    const res = await fetch(`https://api.botcalculator.com/v1/user/api`, {
      headers: {
        AuthToken: token || "",
      },
    });
    const json = await res.json();

    setApiKeys(json.Data);

    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <Island>
        <Heading as="h1" size="lg" mb={8}>
          {t("api_keys:title")}
        </Heading>

        <Spinner />
      </Island>
    );
  }

  return (
    <>
      <Island>
        <Heading as="h1" size="lg" mb={8}>
          {t("api_keys:title")}
        </Heading>

        <Modal isCentered size="md" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <Multistep refetch={loadData} onClose={onClose} burza={burza} />
          </ModalContent>
        </Modal>

        <ApiKeysTable items={apiKeys} setBurza={setBurza} onOpen={onOpen} />

        <Text my={4} align="center">
          {t("api_keys:text_3")}
        </Text>
      </Island>
      <Island>
        <Text fontSize={36} mb={8}>
          {t("api_keys:title_3")}
        </Text>
        <Text mb={4} align="center">
          {t("api_keys:text_7")}
          <br /> {t("api_keys:text_8")}
        </Text>
        <Flex
          flex={1}
          gap={10}
          flexDir={{ base: "column", md: "row", lg: "row" }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Image
              src="binance-logo.png"
              my={5}
              style={{ objectFit: "contain", cursor: "pointer" }}
              maxW={{ base: "100%", md: "50%", lg: "50%" }}
              onClick={() =>
                window.open(
                  "https://accounts.binance.com/register?ref=BFDRW8BB"
                )
              }
            />

            <ReactPlayer
              url="https://www.youtube.com/watch?v=KRM7hjTV3ys&ab_channel=Tom%C3%A1%C5%A1Velek"
              width={"100%"}
            />
          </Flex>

          <Flex
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Image
              my={5}
              src="phemex-logo.png"
              style={{ objectFit: "contain", cursor: "pointer" }}
              maxW={{ base: "100%", md: "50%", lg: "50%" }}
              onClick={() => window.open("https://phemex.com/a/k/hodlbot")}
            />
            <ReactPlayer
              url="https://www.youtube.com/watch?v=nUxcXnHebA8&ab_channel=Tom%C3%A1%C5%A1Velek"
              width={"100%"}
            />
          </Flex>
        </Flex>
      </Island>
    </>
  );
};

export default ApiKeys;
