import { useMutation } from "@tanstack/react-query";
import { directus } from "../directus";

type Props = {
  api_id: string;
  state: string;
};

export const useDcaStateUpdate = () =>
  useMutation({
    mutationFn: async ({ api_id, state }: Props) => {
      const token = await directus.auth.token;

      const res = await fetch(
        `https://api.botcalculator.com/v1/dca/dca_instance/detail/state/update`,
        {
          method: "POST",
          headers: {
            AuthToken: token || "",
          },
          body: JSON.stringify({
            Instance_id: api_id,
            New_state: state,
          }),
        }
      );
      const json = await res.json();

      return json;
    },
  });
