import {
  NumberInput as ChakraInput,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputField,
  NumberInputStepper,
  NumberInputProps,
  Text,
  InputRightElement,
  InputGroup,
} from "@chakra-ui/react";

type Props = {
  showPercentage?: boolean;
  disabled?: boolean;
} & NumberInputProps;

const NumberInput = ({ showPercentage, borderColor, ...rest }: Props) => {
  return (
    <ChakraInput
      border="1px solid"
      {...rest}
      borderColor={borderColor}
      borderRadius="md"
    >
      <InputGroup>
        <NumberInputField
          border={0}
          _disabled={{
            opacity: 1,
            cursor: "not-allowed",
          }}
        />
        {showPercentage && (
          <InputRightElement children={<Text>%</Text>} w="min" mr={8} />
        )}
      </InputGroup>
      <NumberInputStepper borderColor={borderColor}>
        <NumberIncrementStepper borderColor={borderColor} />
        <NumberDecrementStepper borderColor={borderColor} />
      </NumberInputStepper>
    </ChakraInput>
  );
};

export default NumberInput;
