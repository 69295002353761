import { Heading, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { SessionCtx } from "../context/SessionContext";

import Island from "../components/Island";

type Props = {
  children?: React.ReactNode;
};

const Secured = ({ children }: Props) => {
  const { user } = useContext(SessionCtx);

  if (user) {
    return <>{children}</>;
  }

  return (
    <Island>
      <Heading as="h1" size="lg" mb={4}>
        Uživatel nepřihlášen
      </Heading>
      <Text my={5} mb={4}>
        Pro přístup na tuto stránku se musíte přihlásit.
      </Text>
    </Island>
  );
};

export default Secured;
