import { type } from "os";
import {
  Button,
  Flex,
  Heading,
  Text,
  Image,
  Spinner,
  VStack,
  HStack,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  items: {
    ClientId_S: string;
    Exchange: string;
    ExpireAt: string;
    Id: string;
    Key_S: string;
    Name: string;
    Sectet_S: string;
  }[];
  setBurza: Dispatch<
    SetStateAction<"BINANCE" | "PHEMEX" | "COINMATE" | "BYBIT" | undefined>
  >;
  onOpen: () => void;
};

type ItemProps = {
  ClientId_S: string;
  Name: string;
  Sectet_S: string;
  Key_S: string;
  logo: string;
  link: string;
  onClick: () => void;
  text1: string;
  text2: string;
  imageWidth?: number;
};

const Item = ({
  logo,
  ClientId_S,
  Name,
  Sectet_S,
  Key_S,
  onClick,
  text1,
  text2,
  imageWidth,
}: ItemProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      bg="bg"
      p={8}
      borderRadius={10}
      justifyContent="space-between"
      style={{
        width: "100%",
        alignItems: "center",
        gap: 10,
      }}
    >
      <Flex flexDir="column" gap={4}>
        <Image
          src={logo}
          style={{
            objectFit: "contain",
            cursor: "pointer",
            width: imageWidth ?? 200,
          }}
        />

        {!ClientId_S && !Sectet_S && !Key_S && (
          <Text>{t("api_keys:not_connected")}</Text>
        )}

        {ClientId_S && (
          <HStack>
            <Text>Client ID: {ClientId_S}</Text>
          </HStack>
        )}

        {Sectet_S && (
          <HStack>
            <Text>Secret Key: {Sectet_S}</Text>
          </HStack>
        )}

        {Key_S && (
          <HStack>
            <Text>Api Key: {Key_S}</Text>
          </HStack>
        )}
      </Flex>

      <Flex flexDir="column" gap={4}>
        <Button
          variant="outline"
          onClick={() => window.open("https://coinmate.io/en")}
          _hover={{
            color: "brand",
            borderColor: "brand",
          }}
        >
          {text1}
        </Button>
        <Button bg="brand" color="island" fontSize={16} onClick={onClick}>
          {text2}
        </Button>
      </Flex>
    </Flex>
  );
};

const DEFAULT_VALUES = {
  ClientId_S: "",
  Name: "",
  Sectet_S: "",
  Key_S: "",
};

export const ApiKeysTable = ({ items, setBurza, onOpen }: Props) => {
  const { t } = useTranslation();

  const binance =
    items.filter((item) => item.Name[0] === "B" && item.Name[1] !== "B")[0] ??
    DEFAULT_VALUES;
  const bybit =
    items.filter((item) => item.Name[0] === "B" && item.Name[1] === "B")[0] ??
    DEFAULT_VALUES;
  const coinmate =
    items.filter((item) => item.Name[0] === "C")[0] ?? DEFAULT_VALUES;
  const phemex =
    items.filter((item) => item.Name[0] === "P")[0] ?? DEFAULT_VALUES;

  console.log(coinmate);

  return (
    <Flex w="full" flexDir="column" gap={5}>
      <Item
        logo="binance-logo.png"
        link="https://accounts.binance.com/register?ref=BFDRW8BB"
        onClick={() => {
          setBurza("BINANCE");
          onOpen();
        }}
        text1={t("api_keys:text_12")}
        text2={t("api_keys:text_10")}
        {...binance}
      />
      {/* <Item
        logo="bybit-logo.png"
        link={"https://www.bybit.com/en/"}
        onClick={() => {
          setBurza("BYBIT");
          onOpen();
        }}
        text1={t("api_keys:text_15")}
        text2={t("api_keys:text_14")}
        imageWidth={80}
        {...bybit}
      />
      <Item
        logo="cm-logo.png"
        link={"https://coinmate.io/en"}
        onClick={() => {
          setBurza("COINMATE");
          onOpen();
        }}
        text1={t("api_keys:text_17")}
        text2={t("api_keys:text_16")}
        {...coinmate}
      /> */}
      <Item
        logo="phemex-logo.png"
        link={"https://phemex.com/a/k/hodlbot"}
        onClick={() => {
          setBurza("PHEMEX");
          onOpen();
        }}
        text1={t("api_keys:text_13")}
        text2={t("api_keys:text_11")}
        {...phemex}
      />
    </Flex>
  );
};
