// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react";

const colors = {
  primary: "rgb(163,141,101)",
  light: "#e3c58f",
  dark: "#4a3f2c",
  bg: "#262338",
  island: "#14142A",
  brand: "#50C8FC",
};

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
  components: {
    Modal: {
      baseStyle: () => ({
        dialog: {
          bg: "island",
        },
      }),
    },
    Button: {
      baseStyle: {
        borderRadius: "full",
        color: "white",
        fontSize: 14,
      },
    },
    Container: {
      baseStyle: {
        maxW: 1200,
      },
    },
    Text: {
      variants: {
        brand: {
          color: colors.brand,
        },
      },
      baseStyle: {
        fontStyle: "normal",
        // fontFamily: "Roboto,Arial Regular,sans-seri",
      },
    },
    Link: {
      variants: {
        brand: {
          color: colors.brand,
        },
      },
    },
    Checkbox: {
      baseStyle: {
        color: "white",
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: 12,
        color: "white",
      },
    },
  },
  styles: {
    global: () => ({
      "html, body": {
        "scroll-behavior": "smooth",
        bg: colors.bg,
        "::-webkit-scrollbar": {
          width: "12px",
        },
        " ::-webkit-scrollbar-track": {
          "background-color": "#262338",
          border: "1px solid #14142A",
          "box-shadow": "inset 0 0 6px rgba(0, 0, 0, .3)",
        },

        "::-webkit-scrollbar-thumb": {
          "background-color": "rgba(0, 0, 0, .3)",
        },
      },
    }),
  },
  colors,
});

export default theme;
