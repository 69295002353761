import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import { directus } from "../api/directus";
import { ItemInput, UserItem } from "@directus/sdk";
import Island from "../components/Island";
import Input from "../components/Input";
import { toast } from "react-toastify";
import Autocomplete from "react-autocomplete";
import { countries } from "../utils/countries";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [myBilling, setMyBilling] = useState<any>(undefined);

  const [me, setMe] = useState<ItemInput<UserItem<unknown>> | undefined>(
    undefined
  );

  const [search, setSearch] = useState(myBilling?.country ?? "");

  const formik = useFormik({
    initialValues: {
      ...me,
      ...myBilling,
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      console.log("f", values);
      try {
        await directus.users.me.update({
          billing: {
            ...myBilling,
            country: values.country,
            town: values.town,
            street: values.street,
            psc: values.psc,
            phone: values.phone,
          },
          marketing_emails: values.marketing_emails,
        });
        await fetchMyBilling();
        toast(t("profile:notif_success"), { type: "success" });
      } catch (e) {
        const errMsg = (e as any)?.message ?? t("profile:notif_error");
        toast(errMsg, { type: "error" });
      }

      setIsLoading(false);
    },
  });

  const fetchMyBilling = useCallback(async () => {
    const me = await directus.users.me.read();
    console.log(me);
    setMe(me);
    formik.setValues(me);

    if (!me?.billing) {
      setMyBilling(undefined);
    } else {
      const billing = await directus.items("aa_billing").readOne(me.billing, {
        fields: ["country", "town", "street", "psc", "phone", "id"],
      });

      setMyBilling(billing);
      setSearch(billing?.country ?? "");
      formik.setValues({ ...me, ...billing });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchMyBilling();
  }, [fetchMyBilling]);

  return (
    <>
      <Island>
        <Flex flexDir="column" mb={6} w="full">
          <Heading color="white" fontSize={"4xl"}>
            {t("profile:title")}
          </Heading>
          <Text color="white" fontSize={"lg"}>
            {t("profile:text_1")}
          </Text>
        </Flex>
        <Flex flexDir="column" w="full">
          <form onSubmit={formik.handleSubmit}>
            <Text color="white" fontSize={"lg"} fontWeight="bold" mb={6}>
              {t("profile:text_2")}
            </Text>
            <Stack spacing={4}>
              <Stack
                spacing={0}
                flexDir={{ sm: "row", base: "column" }}
                color="white"
              >
                <FormControl mr={2}>
                  <FormLabel htmlFor="first_name">
                    {t("profile:text_3")}
                  </FormLabel>
                  <Input
                    id="first_name"
                    name="first_name"
                    type="string"
                    onChange={formik.handleChange}
                    value={formik.values.first_name ?? ""}
                    isDisabled
                    mb={{ sm: 0, base: 4 }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="last_name">
                    {t("profile:text_4")}
                  </FormLabel>
                  <Input
                    id="last_name"
                    name="last_name"
                    type="string"
                    onChange={formik.handleChange}
                    value={formik.values.last_name ?? ""}
                    isDisabled
                  />
                </FormControl>
              </Stack>
              <FormControl>
                <FormLabel htmlFor="email"> {t("profile:text_5")}</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email ?? ""}
                  isDisabled
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="phone">{t("profile:text_6")}</FormLabel>
                <Input
                  id="phone"
                  name="phone"
                  type="phone"
                  onChange={formik.handleChange}
                  value={formik.values.phone ?? ""}
                />
              </FormControl>
              <Text color="white" fontSize={"lg"} fontWeight="bold" py={6}>
                {t("profile:text_7")}
              </Text>
              <Stack
                spacing={0}
                flexDir={{ sm: "row", base: "column" }}
                color="white"
              >
                <FormControl mr={2}>
                  <FormLabel htmlFor="street"> {t("profile:text_8")}</FormLabel>
                  <Input
                    id="street"
                    name="street"
                    type="string"
                    onChange={formik.handleChange}
                    value={formik.values.street ?? ""}
                    mb={{ sm: 0, base: 4 }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="town"> {t("profile:text_9")}</FormLabel>
                  <Input
                    id="town"
                    name="town"
                    type="string"
                    onChange={formik.handleChange}
                    value={formik.values.town ?? ""}
                  />
                </FormControl>
              </Stack>
              <Stack
                spacing={0}
                flexDir={{ sm: "row", base: "column" }}
                color="white"
              >
                <FormControl mr={2}>
                  <FormLabel htmlFor="country">
                    {t("profile:text_11")}
                  </FormLabel>
                  {/* <Input
                    id="country"
                    name="country"
                    type="string"
                    onChange={formik.handleChange}
                    value={formik.values.country ?? ""}
                    mb={{ sm: 0, base: 4 }}
                  /> */}

                  <Autocomplete
                    getItemValue={(item) => item.name}
                    items={countries.filter((c) =>
                      c.name.toLowerCase().includes(search.toLowerCase())
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        style={{
                          background: isHighlighted ? "#262338" : "#14142A",
                          padding: 1,
                        }}
                      >
                        {item.name}
                      </div>
                    )}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onSelect={(val) => {
                      formik.setFieldValue("country", val);
                      setSearch(val);
                    }}
                    menuStyle={{
                      width: "100%",
                      background: "black",
                      maxHeight: "100px",
                      overflow: "auto",
                      position: "absolute",
                      top: 68,
                      left: 0,
                      zIndex: 999,
                    }}
                    wrapperStyle={{ width: "100%" }}
                    inputProps={{
                      style: {
                        width: "100%",
                        color: "white",
                        background: "#14142A",
                        border: "1px solid #6E7191",
                        borderRadius: "5px",
                        height: "40px",
                        padding: "0 16px",
                      },
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="psc"> {t("profile:text_10")}</FormLabel>
                  <Input
                    id="psc"
                    name="psc"
                    type="psc"
                    onChange={formik.handleChange}
                    value={formik.values.psc ?? ""}
                  />
                </FormControl>
              </Stack>

              <Text color="white" fontSize={"lg"} fontWeight="bold" py={6}>
                {t("profile:text_12")}
              </Text>

              <Stack spacing={4}>
                <Stack
                  spacing={0}
                  flexDir={{ sm: "row", base: "column" }}
                  color="white"
                >
                  <FormControl>
                    <Checkbox
                      id="marketing_emails"
                      name="marketing_emails"
                      onChange={formik.handleChange}
                      isChecked={formik.values.marketing_emails}
                    >
                      {t("profile:text_13")}
                    </Checkbox>
                  </FormControl>
                </Stack>
              </Stack>
              <Flex gap={2} flexDir={{ sm: "row", base: "column" }} pt={6}>
                <Button
                  bg="brand"
                  color="island"
                  fontSize={14}
                  type="submit"
                  disabled={isLoading}
                  w="full"
                >
                  {isLoading ? <Spinner /> : t("profile:button_1")}
                </Button>
                <Button
                  bg="brand"
                  color="island"
                  fontSize={14}
                  disabled={!me?.email}
                  w="full"
                  onClick={() => {
                    directus.auth.password.request(me?.email ?? "");
                    toast("Odkaz na resetování hesla byl odeslán", {
                      type: "success",
                    });
                  }}
                >
                  {t("profile:button_2")}
                </Button>
              </Flex>
            </Stack>
          </form>
        </Flex>
      </Island>
    </>
  );
};

export default Profile;
