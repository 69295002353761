import { Flex, Button, Text, Grid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDcaInstances } from "../api/query/useDcaInstances";
import Island from "../components/Island";
import { ReactComponent as GavelIcon } from "../icons/gavel.svg";
import { ReactComponent as AvTimerIcon } from "../icons/av_timer.svg";
import { ReactComponent as AccountBalanceWalletIcon } from "../icons/account_balance_wallet.svg";
import { ReactComponent as TollIcon } from "../icons/toll.svg";
import { ReactComponent as StopIcon } from "../icons/stop.svg";
import { ReactComponent as PlayIcon } from "../icons/play_arrow.svg";
import { ReactComponent as LightBulbIcon } from "../icons/light_bulb_color.svg";

import { ReactComponent as PauseIcon } from "../icons/pause.svg";
import { IoOpenOutline } from "react-icons/io5";

import { useTranslation } from "react-i18next";

const Dca = () => {
  const navigate = useNavigate();
  const { data: instances, isLoading } = useDcaInstances();

  const { t } = useTranslation();

  const stateTransform = (state: string) => {
    switch (state) {
      case "ACTIVE":
        return (
          <Text color="#A6F787" display="flex" alignItems="center" gap={3}>
            <PlayIcon fill="#A6F787" /> {t("dca:active")}
          </Text>
        );
      case "INSUFFICIENT_BALANCE":
        return (
          <Text color="#FFA65A" display="flex" alignItems="center" gap={3}>
            <StopIcon fill="#FFA65A" /> {t("dca:insufficient_balance")}
          </Text>
        );
      case "STOPPED":
        return (
          <Text color="#FF75CB" display="flex" alignItems="center" gap={3}>
            <PauseIcon fill="#FF75CB" /> {t("dca:stopped")}
          </Text>
        );
      case "LIMIT_EXCEEDED":
        return (
          <Text color="#FFA65A" display="flex" alignItems="center" gap={3}>
            <StopIcon fill="#FFA65A" /> {t("dca:limit_exceeded")}
          </Text>
        );
    }
  };

  const frequencyTransform = (frequency: string) => {
    switch (frequency) {
      case "HOURS":
        return t("dca:hours");
      case "DAYS":
        return t("dca:days");
      case "WEEKS":
        return t("dca:weeks");
      case "MONTHS":
        return t("dca:months");
      case "MINUTES":
        return t("dca:minutes");
      default:
        return "-";
    }
  };

  return (
    <>
      <Island
        p={8}
        flexDir="row"
        justifyContent="space-between"
        display={{ base: "none", sm: "flex" }}
      >
        <Flex gap={4} alignItems="center">
          <Text fontSize={32}>DCA</Text>

          <Flex
            borderWidth={1}
            bg="#262338"
            borderColor="#6E7191"
            p={2}
            borderRadius="30px"
            px={4}
          >
            Bežící instance:{" "}
            {instances?.Data?.filter((i: any) => i.Status === "ACTIVE")
              .length ?? 0}
          </Flex>
        </Flex>

        <Button
          bg="brand"
          color="island"
          fontSize={14}
          fontWeight={600}
          onClick={() => navigate("create")}
        >
          + Vytvořit DCA instanci
        </Button>
      </Island>
      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={8}
        textColor="#F7F7FC"
        flexWrap="wrap"
        p={4}
      >
        {!isLoading &&
          instances?.Data?.map((item: any) => (
            <Flex
              key={item.InstanceId}
              flexDir="column"
              bg="island"
              borderRadius={10}
              flex={1}
            >
              <Flex p={4}>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  gap={2}
                >
                  <img
                    src="HodlbotLogo.svg"
                    alt="Hodl Logo"
                    className="logo"
                    style={{
                      width: 38,
                      height: 34,
                    }}
                  />
                  <Text fontSize={18} fontWeight={600}>
                    {item.Name} {item.Ticker}
                  </Text>
                </Flex>

                <Text fontSize={18} fontWeight={600} ml="auto">
                  {stateTransform(item.Status)}
                </Text>
              </Flex>

              <Flex flexDir="column" borderTopWidth={1} borderBottomWidth={1}>
                <Flex p={4} alignItems="center" gap={4}>
                  <GavelIcon fill="#FFFFFF" />
                  <Flex flexDir="column">
                    <Text fontSize={12}> Burza: </Text>
                    <Text fontSize={14}>
                      {item.ApiItem.Name.startsWith("B") ? "Binance" : "Phemex"}
                    </Text>
                  </Flex>
                </Flex>

                <Flex p={4} alignItems="center" gap={4}>
                  <AvTimerIcon fill="#FFFFFF" />

                  <Flex flexDir="column">
                    <Text fontSize={12}> Frekvence: </Text>
                    <Text fontSize={14}>
                      {item.Frequency} {frequencyTransform(item.TimeUnit)}
                    </Text>
                  </Flex>
                </Flex>

                <Flex p={4} alignItems="center" gap={4}>
                  <AccountBalanceWalletIcon fill="#FFFFFF" />
                  <Flex flexDir="column">
                    <Text fontSize={12}> Průměrný nákup: </Text>
                    <Text fontSize={14}>
                      {" "}
                      {item.PriceUnit} {item.Ticker.split("-")[1]}
                    </Text>
                  </Flex>
                </Flex>

                <Flex p={4} alignItems="center" gap={4}>
                  <TollIcon fill="#FFFFFF" />
                  <Flex flexDir="column">
                    <Text fontSize={12}>Objem:</Text>
                    <Text fontSize={14}>
                      {item.Spend} {item.Ticker.split("-")[1]}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex justifyContent="space-between" whiteSpace="nowrap">
                <Flex p={4}>
                  Nakoupeno: {item.Bought} {item.Ticker.split("-")[0]}
                </Flex>
                <Flex
                  p={4}
                  color="brand"
                  cursor="pointer"
                  onClick={() => navigate(`/dca/${item.StateItem}`)}
                  justifyContent="space-between"
                  gap={2}
                  alignItems="center"
                >
                  Detail
                  <IoOpenOutline />
                </Flex>
              </Flex>
            </Flex>
          ))}

        {instances?.Data === null && (
          <Flex
            bg="island"
            borderRadius={10}
            p={8}
            gap={4}
            flex={1}
            alignItems="center"
          >
            <img
              src="no_instances.png"
              alt="Hodl Logo"
              className="logo"
              style={{ width: 160, height: 160 }}
            />

            <Flex flexDir="column" gap={2}>
              <Text fontSize={16} color="#F7F7FC">
                {t("dca:no_instances")}
              </Text>
              <Text fontSize={14} color="#EFF0F6">
                {t("dca:no_instances_info")}
              </Text>
            </Flex>
          </Flex>
        )}

        <Flex
          flexDir="column"
          bg="island"
          borderRadius={10}
          flex="1"
          minH="400px"
        >
          <Flex
            mx={8}
            mt={16}
            bg="#946300"
            p={2}
            borderRadius={8}
            w="min"
            mb={4}
          >
            <LightBulbIcon />
          </Flex>

          <Text mx={8} fontSize={16} mb={4}>
            {t("dca:what_is_dca")}
          </Text>
          <Text mx={8} fontSize={14}>
            {t("dca:what_is_dca_info")}
          </Text>
        </Flex>
      </Grid>
    </>
  );
};

export default Dca;
