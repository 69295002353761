import { Text, FormControl } from "@chakra-ui/react";
import { de } from "date-fns/locale";
import { useState } from "react";
import Autocomplete from "react-autocomplete";

type Props = {
  name: string;
  errors: any;
  touched: any;
  options: any[];
  setFieldValue: any;
  placeholder: string;
  placeholderDisabled: string;
  disabled?: boolean;
  optionLabel: (option: any) => string;
  optionValue: (option: any) => string;
  onSelect?: (val: any) => void;
  defaultValue?: any;
};

const FormAutoComplete = ({
  name,
  errors,
  touched,
  setFieldValue,
  options,
  placeholder,
  placeholderDisabled = "",
  disabled,
  optionLabel,
  optionValue,
  onSelect,
  defaultValue,
}: Props) => {
  const [search, setSearch] = useState(
    defaultValue ? optionLabel(defaultValue) : ""
  );

  return (
    <FormControl mr={2}>
      <Autocomplete
        getItemValue={(item) => optionValue(item)}
        items={options.filter((i) =>
          optionLabel(i).toLowerCase().includes(search.toLowerCase())
        )}
        renderItem={(item, isHighlighted) => (
          <div
            style={{
              background: isHighlighted ? "#262338" : "#14142A",
              padding: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            {optionLabel(item)}
          </div>
        )}
        renderInput={(props) => (
          <input
            {...props}
            style={{
              width: "100%",
              color: "white",
              background: "#14142A",
              border: "1px solid #6E7191",
              borderRadius: "5px",
              height: "40px",
              padding: "0 16px",
            }}
          ></input>
        )}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onSelect={(val) => {
          setFieldValue(name, val);

          const item = options.find((i) => optionValue(i) === val);

          setSearch(optionLabel(item));
          onSelect?.(val);
        }}
        menuStyle={{
          width: "100%",
          background: "black",
          maxHeight: "100px",
          overflow: "auto",
          position: "absolute",
          border: "1px solid #6E7191",
          borderRadius: "5px",
          top: 50,
          left: 0,
          zIndex: 9999999,
        }}
        wrapperStyle={{ width: "100%" }}
        inputProps={{
          disabled,
          placeholder: disabled ? placeholderDisabled : placeholder,
          style: {
            cursor: disabled ? "not-allowed" : "text",
            width: "100%",
            color: "white",
            background: "#14142A",
            border: "1px solid #6E7191",
            borderRadius: "5px",
            height: "40px",
            padding: "0 16px",
          },
        }}
      />
      {errors?.[name] && touched?.[name] ? (
        <Text color="#9E0038">{errors?.[name]}</Text>
      ) : null}
    </FormControl>
  );
};

export default FormAutoComplete;
