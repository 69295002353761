import { ListItem, OrderedList, Link, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import Island from "../components/Island";

const GeneralTerms = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <>
      <Island>
        <Text fontSize="3xl">Obchodní podmínky</Text>
        <Text mb={4} lineHeight="1.8">
          <Text fontSize="xl" my={6}>
            1. Pravidla a podmínky
          </Text>
          a. hodlbot.cz neposkytuje investiční poradenství, finanční
          poradenství, ani investiční doporučení.
          <br />
          b. Nenabízíme žádné analýzy nebo doporučení týkající se nákupu
          investičních instrumentů nebo možností obchodovat na finančních trzích
          krátkodobě či dlouhodobě.
          <br />
          c. Veškeré informace jsou prezentací historické výkonosti softwaru,
          nejedná se o investiční doporučení.
          <br />
          d. Obchodování na finančních trzích je rizikové a nese s sebou možnost
          ztráty části, nebo i celého investičního kapitálu. Prezentované
          dosavadní výsledky nejsou zárukou výsledků budoucích a neodráží
          realitu obchodování na burze.
          <Text fontSize="xl" my={6}>
            2. Úvod a základní termíny
          </Text>
          a. „Poskytovatel“: Algo Traders s.r.o., IČO: 19194536
          <br />
          b. „Klient“: Uživatel, který využívá produkt (produktem je pouze
          pronájem sowftwaru) prezentovaný na webových stránkách hodlbot.cz
          botcalculator.com a stats.botcalculator.com.
          <br />
          c. „Kryptoměna“: virtuální aktivum, jak je definováno zákonem.
          <br />
          d. „DCA“ (Dollar-Cost Averaging): Je investiční strategie, ve které
          investor rozdělí celkové množství částky, kterou chce za dané období
          investovat na menší periodické nákupy daného aktiva, čímž snižuje
          dopad volatility na celkový nákup.
          <br />
          e. „Hodlbot“: Software provozovaný týmem Algo Traders s.r.o.
          Prezentovaný na hodlbot.cz
          <br />
          f. „API Klíč“: Sada unikátních řetězců umožňující bezpečnou komunikaci
          mezi aplikacemi. Například komunikaci aplikace s burzou nebo směnárnou
          za účelem nákupu kryptoměn a zjištění zůstatků na účtu.
          <br />
          g. „Limitní objednávka“: Limitní objednávkou určí klient cenu, za
          kterou je ochoten nakoupit nebo prodat Kryptoměnu. h. „Market
          objednávka“: Je objednávka na nákup nebo prodej Kryptoměny za aktuální
          cenu na dané burze nebo směnárně.
          <br />
          h. “Referenční tabulka”: uspořádání číselných hodnot, které jsou
          definovány klientem na základě kterých se poskytovaný bot spustí. Tato
          tabulka zároveň nejhorší možný scénář obchodování botem.
          <br />
          i. “Obchodovaný pár”: Určuje, jaká aktiva bude bot obchodovat
          (zpravidla je to kraptoěna a fiat ve stablecoinu například BTC/USD
          <br />
          j. “Kapitál”: celková hodnota alokovaných prostředků (aktivum + fiat)
          vyjádřená ve fiatu v době startu instance bota.
          <br />
          k. “Price Unit”: jedna z proměnných hodnot referenční tabulky, který
          definuje hodnotu každé transakce provedené botem. m. „% skok (slovy
          procentuální skok)“: jedná se o minimální hodnotu profitu z každé
          transakce vyjádřenou v procentuálním čísle 1% -{">"} 0,01 n.
          „Poplatek“: poplatek, který si určuje burza na které se klient rozhodl
          obchodovat, znovu je procento vyjádřeno číslem.
          <br />
          l. „Min Buy: jeden z extrémů, jedná se o hodnotu, na kterou pod kterou
          když se dostane klientem množené aktivum, tak bot nebude mít
          prostředky na další dokup. (veškerý kapitál bude alokovaný v množeném
          aktivu a zůstatek fiatu bude menší než priceUnit). „Max Sell“: druhý z
          extrémů, jedná se o hodnotu, nad kterou když se dostane klientem
          množené aktivum, tak bot nebude mít dostatečný počet aktiva k dalšímu
          prodeji. (veškerý kapitál bude alokován ve fiatu a zbytek aktiva bude
          menší než priceUnit na dané ceně – priceUnit/MaxSell)
          <br />
          m. „Dvojobchod“: jen název pro párový obchod nákup a prodej, kde z
          tohoto páru vznikne přebytek v kryptoměně a to je zisk. „Zisk krypto“:
          jedná se o číselnou hodnotu klientovo aktiva, kterou naakumulujete za
          jeden uzavřený dvojobchod. hodnoty „Zůstatek krypto a fiat“: vyjadřuje
          nejhorší možný stav kapitálu na dané ceně za jednotku aktiva, který je
          botem obchodován (krypto + fiat).
          <Text fontSize="xl" my={6}>
            3. Obchodní, dodací a platební podmínky
          </Text>
          a. Poskytovatel je provozovatel webových stránek vzpomenutých v bode
          2b).
          <br />
          b. Hodlbot slouží Klientům k automatizaci nákupů kryptoměn pomocí
          strategie prostřednictvím burz a směnáren, které si klient zvolil sám.
          Tyto obchodní podmínky (dále jen „Obchodní podmínky“) představují
          rámcovou smlouvu, která bude uzavřena mezi Klientem a Poskytovatelem a
          která upravuje práva a povinnosti stran při poskytování služeb
          Poskytovatelem Klientovi (dále jen „Rámcová smlouva“). K uzavření
          Rámcové smlouvy dojde v okamžiku, kdy Klient odsouhlasí znění těchto
          Obchodních podmínek v rámci zaslání konfigurační tabulky.
          <br />
          c. Proces nákupu Kryptoměny pomocí bota:
          <OrderedList ml={12} mt={4}>
            <ListItem>
              Klient si propojí burzu nebo směnárnu s aplikací
            </ListItem>
            <ListItem>
              Zaregistruje se na směnárně prostřednictvím webové nebo mobilní
              aplikace. Zde také odlouhlasí obchodní podmínky té burzy kterou si
              vybral, pokud nastane nějaký problém s dostupností / likviditou
              jeho kapitálu, tak za jeho kapitál je plně zodpovědná burza,
              kterou si vybral, Algo Traders s.r.o. nemá žádný přistup ke
              kapitálu, myšleno vklady, výběry a držení kapitálu klientů. Není
              to možné ani z techniského hlediska. Algo Traders s.r.o. pouze
              pronajímá software.
            </ListItem>
            <ListItem>
              Propojí si svůj účet na burze nebo směnárně s Aplikací tím, že si
              na dané burze nebo směnárně vygeneruje API Klíč, který musí mít{" "}
            </ListItem>
            <ListItem>
              Vygenerované klíče klient pošle Algo Tradrs s.r.o. Klient si na
              propojenou burzu nebo směnárnu vloží prostředky fiat a aktivum v
              poměru definované v referenční tabulce, za účelem nákupu
              Kryptoměn.
            </ListItem>
            <ListItem>
              Klient zasláním referenční tabulky nastaví strategii nákupu na
              konkrétní propojené burze nebo směnárně, která obsahuje nastavení.
              1. Init price 2. % skok 3. priceUnit 4. minBuy 5. maxSell 6. email
              pro informování klienta o stavu portfolia.
            </ListItem>
            <ListItem>
              Frekvence pravidelného nákupu. Používáme výhradně limitní
              objednávky, takže pokud se transakce provede umíme zaručit, že
              byla provedena za vyžadovaných nebo lepších podmínek.
            </ListItem>
            <ListItem>
              Nakoupené Kryptoměny zůstanou na dané burze nebo směnárně, kde byl
              realizován obchod pomocí propojení bota. Poskytovatel nemůže s
              těmito prostředky nakládat.
            </ListItem>
            <ListItem>
              Klient má plné právo i technický přístup kdykoliv Hodlbota
              zastaivt bez poplatků či postihů.
            </ListItem>
          </OrderedList>
          <Text fontSize="xl" my={6}>
            4. Omezení odpovědnosti za újmu
          </Text>
          a. Vzhledem k neustálým změnám tržní hodnoty Kryptoměn tímto Klient
          bere na vědomí a je srozuměn s tím, že jím nakoupené Kryptoměny mohou
          ztratit hodnotu v důsledku vývoje a změn trhu (zejména nabídky a
          poptávky). Poskytovatel odpovídá pouze za provedení nákupní objednávky
          dle Poskytovatelem akceptovaného pokynu Klienta a nenese odpovědnost
          za změnu hodnoty nakoupené Kryptoměny po provedení nákupu.
          <br />
          b. Poskytovatel neodpovídá za žádných okolností za újmu vzniklou na
          straně Klienta a související s:
          <OrderedList ml={12} mt={4}>
            <ListItem>pohybem kurzu Kryptoměn</ListItem>
            <ListItem>
              selháním přenosu dat či jakéhokoliv jiného komunikačního kanálu
              mezi Klientem a Poskytovatelem nebo jakoukoli jinou třetí stranou,
              nedostupností aplikace Poskytovatele iv. selhání jakékoli třetí
              strany ve vztahu k vypořádání pokynu k nákupu Kryptoměn.
            </ListItem>
            <ListItem>
              prodlení Klienta s plněním jeho povinností dle těchto Podmínek.
            </ListItem>
          </OrderedList>
          <br />
          c. Provozovatel platformy nenese zodpovědnost za ztráty nebo škody,
          které mohou uživateli vzniknout používáním software, ani v za ztráty
          nebo škody způsobené chybou software, chybou na stránkách nebo
          výpadkem v provozu. Uživatel užívá software na vlastní nebezpečí.
          <br />
          d. V případě výpadku služby na více než jeden den je provozovatel
          povinný výpadek kompenzovat prodloužením předplatného. Pokud nemůže
          uživatel službu používat z důvodu překážky na straně provozovatele, je
          povinností provozovatele vyplatit uživateli nevyužitou část
          předplatného
          <Text fontSize="xl" my={6}>
            5. Zpracování osobních údajů
          </Text>
          a. Poskytovatel zpracovává a uchovává osobní údaje Klienta v souladu s
          Nařízením Evropského parlamentu a Rady (EU) 2016/679, o ochraně
          fyzických osob v souvislosti se zpracováním osobních údajů a o volném
          pohybu těchto údajů (GDPR) a zákonem 110/2019 Sb., o zpracování
          osobních údajů a související právními předpisy.
          <br />
          b. Zásady zpracování osobních údajů a informace o zpracování osobních
          údajů jsou zveřejněny na internetových stránkách Poskytovatele v sekci
          Zásady zpracování osobních údajů a Klient se s těmito seznámil a
          vyslovuje s nimi svůj souhlas.
          <br />
          c. Odkaz na zásady zpracování{" "}
          <Link
            variant="brand"
            as={RouterLink}
            to="/privacy_policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            osobních údajů
          </Link>
          <Text fontSize="xl" my={6}>
            6. Upozornění
          </Text>
          a. Poskytovatel upozorňuje, že obsah jeho webové stránky, na které
          nabízí služby, může obsahovat neaktuální informace, chyby nebo
          nepřesnosti, a vyhrazuje si právo provést opravu, úpravu nebo
          aktualizaci obsahu webové stránky, považuje-li to za nutné nebo
          vhodné.
          <br />
          b. Jakékoliv informace dostupné na webových stránkách Poskytovatele
          nepředstavují investiční, daňové, právní, příp. jiné poradenství, ani
          návrh na uzavření smlouvy o poskytování finanční, investiční nebo jiné
          služby a ani jakýkoliv jiný návrh Poskytovatele vůči potencionálnímu
          Klientovi.
          <br />
          c. Poskytovatel neposkytuje Klientům žádná doporučení ohledně nákupu a
          prodeje Kryptoměn, investiční průzkumy nebo finanční analýzy a podobné
          služby. Veškeré informace uvedené na webových stránkách Poskytovatele
          nemají doporučující charakter a jejich cílem je pouze zdarma
          zpřístupnit danou informaci potencionálnímu Klientovi.
          <br />
          d. Poskytovatel není povinen zajistit nepřetržitou přístupnost
          webových stránek a nezaručuje žádnou minimální dobu odezvy webových
          stránek na požadavky Klienta. Provoz a funkčnost webových stránek může
          být ovlivněn faktory ležícími mimo sféru vlivu Poskytovatele a
          Poskytovatel nepřebírá odpovědnost za jejich následky.
          <Text fontSize="xl" my={6}>
            7. Změny Obchodních podmínek
          </Text>
          a. Poskytovatel je oprávněn obchodní podmínky jednostranně měnit,
          přičemž v souladu s nařízením Evropského parlamentu a Rady (EU) č.
          2019/1150 tyto změny, resp. nové znění obchodních podmínek zveřejní na
          svých internetových stránkách a stávající dotčené uživatelé o něm
          adresně v rámci Systému či jiným způsobem informuje. Dotčení uživatelé
          svým dalším užitím aplikace hodlbot.cz od spolešnosti Algo Traders
          s.r.o. po provedení změny obchodních podmínek stvrzují přijetí takové
          změny.
          <br />
          b. Veškeré změny na stránkách, ve vymezení služby, nebo licenčních
          podmínek budou oznámeny na hlavní stránce v sekcí „Platform news“
          <Text fontSize="xl" my={6}>
            8. Rozhodné právo a příslušné soudy
          </Text>
          a. Jakékoliv právní vztahy vzniklé na základě nebo v důsledku
          využívání služeb Poskytovatele se budou řídit právem České republiky.
          <br />
          b. Jakékoliv spory vzniklé na základě nebo v důsledku využívání služeb
          Poskytovatele budou řešeny příslušnými soudy České republiky.
          <br />
          c. Klient bere na vědomí, že orgánem dozoru nad dodržováním povinností
          Poskytovatele stanovených na ochranu spotřebitele je Česká obchodní
          inspekce.
          <Text fontSize="xl" my={6}>
            9. Práva a povinnosti uživatele
          </Text>
          a. Službu může používat uživatel ve věku 18 a více.
          <br />
          b. Pro využívání služby je uživatel povinný si vlastnit účet založený
          na burze s veškerým ověřením, který burza vyžaduje.
          <br />
          c. Uživatel sám ručí za svoje ztráty. Hodlbot.eu za jakékoliv ztráty
          neručí.
          <br />
          d. Uživatel se musí identifikovat prostřednictvím e-mailu.
          <Text fontSize="xl" my={6}>
            10. Zakázané aktivity
          </Text>
          a. Zneužití komunikačních kanálů
          <br />
          b. Zasílání nezákonného obsahu do informačních kanálů
          <br />
          c. Rozesílání reklamy
          <br />
          d. Využívání nalezených chyb v systému ve svůj prospěch bez
          předchozího ohlášení
          <br />
          e. Přeprodávání provozovaného software třetím stranám
          <br />
          f. Provozování software pro třetí stranu bez patřičného zákonného
          oprávnění
          <Text fontSize="xl" my={6}>
            11. Placené služby
          </Text>
          a. Všechny placené služby jsou placené v CZK, EUR, stablecoinech nebo
          Bitcoinech podle aktuálně platného kurzu ČNB, přípafdně kurzy na
          platformě Binance.com, výše je dána ceníkem formou předplatného, který
          je individuálně navrhován klientům v závistlosti od jejich portfolií.
          Případně jak je prezentováno na webu hodlbot.cz Předplatné, které
          uživatel nedokáže využít v celé délce není možné vyplatit zpět. Provoz
          software v režimu „pause/waiting“ je nadále považováno jako čerpání
          předplatného, pouze software v režimu „stop“ také pozastavuje čerpání
          předplatného.
          <Text fontSize="xl" my={6}>
            12. Závěrečné informace
          </Text>
          a. Tyto Zásady jsou platné od 1. 12. 2022. Jakékoliv dotazy a
          požadavky ve vztahu ke zpracování osobních údajů s námi můžete
          konzultovat prostřednictvím e-mailové adresy: info@hodlbot.cz
        </Text>
      </Island>
    </>
  );
};

export default GeneralTerms;
