import {
  Text,
  Box,
  Flex,
  ListItem,
  OrderedList,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
} from "@chakra-ui/react";
import { useEffect } from "react";
import Island from "../components/Island";

import ReactPlayer from "react-player/youtube";
import { useTranslation } from "react-i18next";

const Hodl_x_Hodl_bot = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  return (
    <>
      <Island>
        <Flex
          flexDir={{ base: "column", md: "row" }}
          bg="bg"
          borderRadius="lg"
          p={8}
        >
          <Flex flexDir="column">
            <Text mb={4} fontSize="3xl" align="center">
              <b>{t("hodl_x_hodlbot:title")}</b>
            </Text>
            <Text mb={4} lineHeight="1.8">
              {t("hodl_x_hodlbot:text_1")}
              <br />
              <br />
              {t("hodl_x_hodlbot:text_2")}
              <br />
              <br />
              {t("hodl_x_hodlbot:text_3")}
            </Text>
          </Flex>
        </Flex>
        <Flex
          flexDir={{ base: "column", md: "row" }}
          borderRadius="lg"
          w="full"
          my={8}
        >
          <Flex flexDir="column" w="full">
            <Flex justifyContent="center" alignItems="center" w="full">
              <ReactPlayer url="https://www.youtube.com/watch?v=-Yk7t2rrkfo&ab_channel=Tom%C3%A1%C5%A1Velek" />
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDir="column" bg="bg" p={8} borderRadius="lg" w="full">
          <Text mb={4} lineHeight="1.8">
            <b> {t("hodl_x_hodlbot:title_2")}</b> - {t("hodl_x_hodlbot:text_4")}
            <br />
            <br />
            {t("hodl_x_hodlbot:text_5")}
            <br />
            <br />
            {t("hodl_x_hodlbot:text_6")}
            <br />
            <br />
            {t("hodl_x_hodlbot:text_7")}
            <br />
            <OrderedList>
              <ListItem>{t("hodl_x_hodlbot:text_8")}</ListItem>
              <ListItem>{t("hodl_x_hodlbot:text_9")} </ListItem>
            </OrderedList>
            <br />
            <br />
            {t("hodl_x_hodlbot:text_10")}
          </Text>

          <img
            src="graf.png"
            style={{
              borderRadius: 10,
              width: 1000,
              height: "auto",
            }}
          />
        </Flex>

        <Flex
          flexDir={{ base: "column", md: "row" }}
          my={8}
          bg="bg"
          p={8}
          borderRadius="lg"
          w="full"
          justifyContent="center"
          alignItems="center"
        >
          <Accordion allowToggle w="full">
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {t("hodl_x_hodlbot:text_11")}
                  </Box>

                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Divider mb={4} />
                {t("hodl_x_hodlbot:text_12")}
                <br />
                <br />
                {t("hodl_x_hodlbot:text_13")}
                <br />
                <br />
                {t("hodl_x_hodlbot:text_14")}
                <br />
                <br />
                {t("hodl_x_hodlbot:text_15")}
                <br />
                <br />
                {t("hodl_x_hodlbot:text_16")}
                <br />
                <br />
                {t("hodl_x_hodlbot:text_17")}
                <br />
                <br />
                {t("hodl_x_hodlbot:text_18")}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Flex>
      </Island>
    </>
  );
};

export default Hodl_x_Hodl_bot;
