import { Flex, FlexProps } from "@chakra-ui/react";

type Props = {
  children: React.ReactNode;
  id?: string;
} & FlexProps;

const Island = ({ id, children, ...rest }: Props) => {
  return (
    <Flex
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      m={{ base: 0, md: 4, lg: 4 }}
      my={4}
      bg="island"
      p={8}
      borderRadius={10}
      id={id}
      color="white"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default Island;
