import {
  Text,
  FormControl,
  Input,
  InputProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Flex,
} from "@chakra-ui/react";

type Props = {
  name: string;
  errors: any;
  touched: any;
  handleChange: any;
  values: any;
  extension?: string;
  Icon?: any;
} & InputProps;

const FormInput = ({
  name,
  errors,
  touched,
  handleChange,
  values,
  extension,
  Icon,
  ...props
}: Props) => {
  return (
    <FormControl>
      <InputGroup>
        {Icon && (
          <InputLeftElement pointerEvents="none">
            <Icon />
          </InputLeftElement>
        )}
        <Input
          id={name}
          name={name}
          type="string"
          onChange={handleChange}
          value={values?.[name]}
          placeholder={""}
          bg="island"
          _disabled={{ bg: "bg", cursor: "not-allowed" }}
          border="1px solid #6E7191"
          {...props}
        />
        {extension && (
          <InputRightElement
            pointerEvents="none"
            pr={extension.length === 1 ? 1 : extension.length * 2}
          >
            <Flex>{extension}</Flex>
          </InputRightElement>
        )}
      </InputGroup>
      {errors?.[name] && touched?.[name] ? (
        <Text color="#9E0038">{errors?.[name]}</Text>
      ) : null}
    </FormControl>
  );
};

export default FormInput;
