import React, { useState } from "react";
import {
  Button,
  Text,
  HStack,
  VStack,
  Image,
  Checkbox,
  Divider,
  Link,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type Form1Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

export const Binance = ({ setStep }: Form1Props) => {
  const { t } = useTranslation();

  const [showBinance, setShowBinance] = useState(false);

  return (
    <VStack m={5} spacing={5} justifyContent="space-between" h="full">
      <Image
        src="binance-logo.png"
        style={{
          objectFit: "contain",
          cursor: "pointer",
          marginBottom: 14,
        }}
        onClick={() =>
          window.open("https://accounts.binance.com/register?ref=BFDRW8BB")
        }
      />
      <VStack>
        <Text align="center" mb={5} color="white">
          {t("api_keys:binance")}
        </Text>

        <Divider />

        <HStack spacing={5}>
          <Checkbox
            isChecked={showBinance}
            onChange={() => setShowBinance(!showBinance)}
          />
          <Text color="white" pb={5}>
            {t("api_keys:text_5:part_1")}{" "}
            <Link
              color="brand"
              href="https://www.youtube.com/watch?v=KRM7hjTV3ys&ab_channel=Tom%C3%A1%C5%A1Velek"
            >
              {t("api_keys:text_5:part_2")}{" "}
            </Link>{" "}
            {t("api_keys:text_5:binance")}
          </Text>
        </HStack>

        {showBinance && (
          <Button
            mt={4}
            bg="brand"
            color="island"
            fontSize={16}
            fontWeight={400}
            onClick={() => {
              setStep(2);
            }}
          >
            {t("api_keys:text_9:part_1")} {t("api_keys:text_9:binance")}
          </Button>
        )}
      </VStack>
    </VStack>
  );
};
