import {
  Flex,
  Text,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import Island from "../components/Island";
import { TableRecord } from "../utils/types";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import img_1 from "../images/1.png";
import img_2 from "../images/2.png";
import { SessionCtx } from "../context/SessionContext";
import { directus } from "../api/directus";
import PortfolioCard from "../components/PortfolioCard";
import { useTranslation } from "react-i18next";

const dark = (number: number, higherThan: number) =>
  number !== 0 ? "#A6F787" : "#FF75CB";

const MyStatistics = () => {
  const { t } = useTranslation();

  const { user } = useContext(SessionCtx);
  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  const [isLoading, setIsLoading] = useState(true);

  const [showContent, setShowContent] = useState<
    { type: "table" | "graph" | "calculator"; instanceId: string }[]
  >([]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const [tableData, setTableData] = useState<TableRecord[]>([]);

  // Initial data fetch.
  useEffect(() => {
    console.log(user);

    const fetchData = async () => {
      const token = await directus.auth.token;

      const res = await fetch(
        `https://api.botcalculator.com/hb/v1/stats/user/current_data?user_id=${
          user?.id ?? ""
        }`,
        {
          headers: {
            AuthToken: token || "",
          },
        }
      );

      const json = await res.json();
      setTableData(json ?? []);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <>
        <Island>
          <Text align="center" fontSize="3xl" width="full" mb={10}>
            {t("portfolio:title")}
          </Text>
          <Flex justifyContent="center" alignItems="center" h="full">
            <Spinner />
          </Flex>
        </Island>
      </>
    );
  }

  if (!tableData) {
    return (
      <>
        <Island>
          <Text align="center" fontSize="3xl" width="full" mb={10}>
            {t("portfolio:title")}
          </Text>
          <Text>{t("portfolio:no_statistics")}</Text>
        </Island>
      </>
    );
  }

  return (
    <>
      <Island>
        <Text align="center" fontSize="3xl" width="full" mb={10}>
          {t("portfolio:title")}
        </Text>
        {/* 
        {isLoading && (
          <Flex justifyContent="center" alignItems="center" h="full">
            <Spinner />
          </Flex>
        )} */}

        {tableData && tableData.length > 0 && (
          <>
            <>
              <Tooltip anchorId="profit_1" style={{ opacity: 1 }}>
                <div>
                  <img alt="1" src={img_1} />
                </div>
              </Tooltip>
              <Tooltip anchorId="profit_2" style={{ opacity: 1 }}>
                <div>
                  <img alt="1" src={img_2} />
                </div>
              </Tooltip>

              {tableData && (
                <Flex
                  mt={10}
                  borderRadius="lg"
                  overflow="hidden"
                  overflowX="auto"
                  w="full"
                  css={{
                    "::-webkit-scrollbar": {
                      width: "12px",
                    },
                    "::-webkit-scrollbar-track": {
                      "background-color": "#262338",
                      border: "1px solid #14142A",
                      "box-shadow": "inset 0 0 6px rgba(0, 0, 0, .3)",
                    },

                    "::-webkit-scrollbar-thumb": {
                      "background-color": "rgba(0, 0, 0, .3)",
                    },
                  }}
                >
                  <Table>
                    <Thead position="sticky" top={0} bg="#4E4B66">
                      <Tr>
                        <Th color="white">Symbol</Th>
                        <Th align="right" color="white">
                          Instance ID
                        </Th>
                        <Th align="right" color="white">
                          Buy
                        </Th>
                        <Th align="right" color="white">
                          Sell
                        </Th>
                        <Th align="right" color="white">
                          Transactions per month
                        </Th>
                        <Th align="right" color="white">
                          Profit crypto
                        </Th>

                        <Th id="profit_1" align="right" color="white">
                          Profit of portfolio [%]
                        </Th>

                        <Th id="profit_2" align="left" color="white">
                          Profit of portfolio2 [%] *
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {tableData.map((row) => (
                        <Tr key={row.InstanceId} height={10}>
                          <Th
                            scope="row"
                            color="#EFF0F6"
                            fontWeight="normal"
                            border="1px solid #262338"
                          >
                            {row.Symbol}
                          </Th>
                          <Th
                            align="right"
                            color="#EFF0F6"
                            fontWeight="normal"
                            border="1px solid #262338"
                          >
                            {row.InstanceName}
                          </Th>
                          <Th
                            align="right"
                            color="#EFF0F6"
                            fontWeight="normal"
                            border="1px solid #262338"
                          >
                            {row.Buy}
                          </Th>
                          <Th
                            align="right"
                            color="#EFF0F6"
                            fontWeight="normal"
                            border="1px solid #262338"
                          >
                            {row.Sell}
                          </Th>
                          <Th
                            align="right"
                            color={dark(row.TransactionAverage, 200)}
                            border="1px solid #262338"
                          >
                            {row.TransactionAverage}
                          </Th>
                          <Th
                            align="right"
                            color={dark(row.ProfitCrypto, 0)}
                            border="1px solid #262338"
                          >
                            {row.ProfitCrypto}
                          </Th>
                          <Th
                            align="right"
                            color={dark(row.PP1, 0)}
                            border="1px solid #262338"
                          >
                            {row.PP1}
                          </Th>
                          <Th
                            align="right"
                            color={dark(row.PP2, 0)}
                            border="1px solid #262338"
                          >
                            {row.PP2}
                          </Th>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
              )}
            </>

            {tableData && (
              <Flex flexDir="column">
                <Flex
                  flexWrap="wrap"
                  display="grid"
                  justifyContent="center"
                  alignItems="center"
                  gridTemplateColumns={{
                    base: "repeat(1, 1fr)",
                    md: "repeat(1, 1fr)",
                    lg: "repeat(2, 1fr)",
                    xl: "repeat(3, 1fr)",
                  }}
                  gridRowGap={10}
                  w="full"
                  py={10}
                >
                  {tableData
                    .slice(0, readMore ? tableData.length + 1 : 3)
                    .map((item) => (
                      <PortfolioCard
                        item={item}
                        showContent={showContent}
                        setShowContent={setShowContent}
                      />
                    ))}
                </Flex>

                {tableData.length > 3 && (
                  <Flex justifyContent="center" alignContent="center">
                    <Button
                      bg="brand"
                      color="island"
                      fontSize={16}
                      onClick={toggleReadMore}
                    >
                      <Text fontSize={14} color="#14142A">
                        {readMore
                          ? t("portfolio:show_less")
                          : `${t("portfolio:show_more")} (${
                              tableData.length - 3
                            })`}
                      </Text>
                    </Button>
                  </Flex>
                )}
              </Flex>
            )}
          </>
        )}
      </Island>
    </>
  );
};

export default MyStatistics;
