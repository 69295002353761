import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../trans/i18n";

const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            mr={4}
            transition="all 0.3s"
            _focus={{ boxShadow: "none" }}
          >
            <Box color="white">
              {i18n.language === "en" ? "EN" : "CS"}{" "}
              {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Box>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => changeLanguage("en")}>
              <Radio value="en" isChecked={i18n.language === "en"}>
                English - EN
              </Radio>
            </MenuItem>
            <MenuItem onClick={() => changeLanguage("cs")}>
              <Radio value="cs" isChecked={i18n.language === "cs"}>
                Čeština - CS
              </Radio>
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default LanguageSwitch;
