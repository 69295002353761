import { Flex, Button, Text } from "@chakra-ui/react";
import Article from "./Article";
import React, { useState } from "react";

const Articles = () => {
  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  const data: {
    site: string;
    name: string;
    text: string;
    image: string;
    link: string;
    date: string;
  }[] = [
    {
      site: "trader20.sk",
      name: "Hodlbot naakumuloval klientům už čtvrt Bitcoinu.",
      text: "Hodlbot mi v mém ukázkovém portfoliu naakumuloval už 60 % Bitcoinu (0,01657 BTC) navíc oproti mému zůstatku na začátku, a to za rok a půl “nicnedělání = hodlování.” ",
      image:
        "https://trader20.sk/wp-content/uploads/2023/12/hodlbot2-1024x631.png.webp",
      link: "https://trader20.sk/hodlbot-naakumuloval-klientum-uz-ctvrt-bitcoinu/",
      date: "19.5.2023",
    },
    {
      site: "youtube.com",
      name: "HODLBOT - Appka pre každého HODLera Bitcoinu | HOSŤ : Tomáš Velek",
      text: "Diskuze a predstaveni HODL bota",
      image: "https://i.ytimg.com/vi/A3xHke7XOTc/hqdefault.jpg",
      link: "https://www.youtube.com/watch?v=A3xHke7XOTc",
      date: "19.5.2023",
    },
    {
      site: "kryptoguru.cz",
      name: "Hodluj, ale chytře s Hodlbotem!",
      text: "Tohle je heslo, pod kterým si každý může představit něco jiného. Já si pod ním představuji odměny z hodlování Bitcoinu!",
      image:
        "https://kryptoguru.cz/wp-content/uploads/2023/05/pexels-rdne-stock-project-8369770.jpg",
      link: "https://kryptoguru.cz/hodluj-ale-chytre-s-hodlbotem/",
      date: "19.5.2023",
    },
    {
      site: "youtube.com",
      name: "Ukázkové portfolio - Snížení průměrné nákupní ceny o $ 15 000",
      text: "Jedná se o automatizovaný matematický systém, který je postavený tak aby využíval volatility Bitcoinu.",
      image:
        "https://i.ytimg.com/vi/6xl8iBU6szI/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAcw00V1RSK2DEMMMXImH2P7CgUvQ",
      link: "https://www.youtube.com/watch?v=6xl8iBU6szI&ab_channel=Tom%C3%A1%C5%A1Velek",
      date: "10.5.2023",
    },
    {
      site: "trader20.sk",
      name: "Jak získat něco navíc z HODL portfolia?",
      text: "Možná otázka, kterou si pokládáte, protože váš HODL je často velmi velká část vašeho portfolia v kryptoměnách. Některé coiny a tokeny se nechají...",
      image:
        "https://trader20.sk/wp-content/uploads/2023/06/hodlbot-1024x614.jpg.webp",
      link: "https://trader20.sk/jak-ziskat-neco-navic-z-hodl-portfolia/",
      date: "11.11.2022",
    },
    {
      site: "trader20.sk",
      name: "Ako si znížiť priemernú nákupku?",
      text: "Väčšina ľudí pri svojom hodle hľadá spôsob ako ho využiť viac ako na to aby ležal na trezore. Sú k dispozícii rôzne likvidity pooly alebo staking.",
      image:
        "https://trader20.sk/wp-content/uploads/2023/06/hodlbot-1024x614.jpg.webp",
      link: "https://trader20.sk/ako-si-znizit-priemernu-nakupku/",
      date: "9.2.2023",
    },
    {
      site: "tradecz.cz",
      name: "Hodlbot ukázkové portfolio",
      text: "Po minulém článku ohledně Hodlbot jsem dostal pár opakujících se otázek, a to konkrétně: Kolik bot vydělá nebo s jak velkým kapitálem lze začít.",
      image: "https://www.tradecz.cz/wp-content/uploads/2022/06/hodlbotpic.png",
      link: "https://www.tradecz.cz/hodlbot-ukazkove-portfolio-pasivni-vydelek-bitcoinu-diky-aos/",
      date: "2.6.2022",
    },
    {
      site: "tradecz.cz",
      name: "Pasivní příjem z HODLování Bitcoinu",
      text: "Tvrdí hodleři jsou připraveni držet Bitcoin za jakýchkoliv okolností s vysokým časovým rámcem. Měkčí hodleři mají předem stanovený plán částečného...",
      image:
        "https://www.tradecz.cz/wp-content/uploads/2022/04/uvodhodlbot.png",
      link: "https://www.tradecz.cz/pasivni-prijem-z-hodlovani-bitcoinu-jak-na-to-bitcoin-bot/",
      date: "28.4.2022",
    },
  ];

  return (
    <Flex flexDir="column">
      <Flex
        flexWrap="wrap"
        display="grid"
        justifyContent="center"
        alignItems="center"
        gridTemplateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
        }}
        gridRowGap={30}
        w="full"
      >
        {data.slice(0, readMore ? 8 : 3).map((article, i) => (
          //  <Flex key={i} w="full" border="1px solid white" justifyContent="center">
          <Article
            name={article.name}
            text={article.text}
            image={article.image}
            site={article.site}
            link={article.link}
            date={article.date}
          />
          //  </Flex>
        ))}
      </Flex>
      <Flex mt={5} justifyContent="center" alignContent="center">
        <Button
          bg="brand"
          color="island"
          fontSize={16}
          onClick={toggleReadMore}
        >
          <Text fontSize={14} color="#14142A">
            {readMore ? "Zobrazit méně" : "Zobrazit více (4)"}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default Articles;
