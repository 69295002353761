import React, { useState } from "react";
import {
  Button,
  Text,
  HStack,
  VStack,
  Image,
  Checkbox,
  Divider,
  Link,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type Form1Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
};

export const Bybit = ({ setStep }: Form1Props) => {
  const { t } = useTranslation();

  const [showBybit, setShowBybit] = useState(false);

  return (
    <VStack h="full" m={5} justifyContent="center" spacing={5}>
      <Image
        src="bybit-logo.png"
        style={{ objectFit: "contain", cursor: "pointer" }}
        onClick={() => window.open("https://www.bybit.com/en/")}
      />
      <VStack>
        <Text align="center" mb={5} color="white">
          {t("api_keys:bybit")}
        </Text>

        <Divider />

        <HStack spacing={5} pb={5}>
          <Checkbox
            isChecked={showBybit}
            onChange={() => setShowBybit(!showBybit)}
          />
          <Text color="white">
            {t("api_keys:text_5:part_1")}{" "}
            <Link
              color="brand"
              href="https://www.youtube.com/watch?v=nUxcXnHebA8&ab_channel=Tom%C3%A1%C5%A1Velek"
            >
              {t("api_keys:text_5:part_2")}{" "}
            </Link>{" "}
            {t("api_keys:text_5:bybit")}
          </Text>
        </HStack>

        {showBybit && (
          <Button
            bg="brand"
            color="island"
            fontSize={16}
            fontWeight={400}
            onClick={() => {
              setStep(2);
            }}
          >
            {t("api_keys:text_9:part_1")} {t("api_keys:text_9:bybit")}
          </Button>
        )}
      </VStack>
    </VStack>
  );
};
