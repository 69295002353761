import {
  FormControl,
  FormLabel,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { SessionCtx } from "../context/SessionContext";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import { ReactComponent as MailIcon } from "../icons/mail.svg";

import Input from "../components/Input";
import PasswordInput from "../components/passwordInput";
import { toast } from "react-toastify";
import "../components/logo.css";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);
  }, []);

  const { login } = useContext(SessionCtx);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await login(values.email, values.password);
        navigate("/");
      } catch (e) {
        const errMsg = (e as any)?.message ?? t("login:notif_error");
        toast(errMsg, { type: "error" });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <Flex
      bg="island"
      w="full"
      height="100vh"
      p={4}
      justifyContent="space-between"
    >
      <Flex flexDir="column" w="full">
        <Flex
          alignItems="center"
          onClick={() => navigate("/")}
          width={150}
          cursor="pointer"
        >
          <Flex overflow="hidden">
            <img
              src="HodlbotLogo.svg"
              alt="Hodl Logo"
              className="logo"
              style={{
                width: 38,
                height: 34,
              }}
            />
          </Flex>
          <Text
            ml={2}
            fontSize="xl"
            variant="brand"
            textTransform="uppercase"
            fontWeight={600}
          >
            Hodlbot
          </Text>
        </Flex>
        <Flex h="full" alignItems="center" justifyContent="center">
          <Stack spacing={8} mx={"auto"} maxW={"xl"} py={12} px={6} w="full">
            <Stack>
              <Heading color="white" fontSize={"4xl"}>
                {t("login:title")}
              </Heading>

              <Text color="white" fontSize={"lg"}>
                {t("login:text_1")}
              </Text>
            </Stack>
            <form onSubmit={formik.handleSubmit}>
              <Stack spacing={4}>
                <FormControl>
                  <FormLabel htmlFor="email"> {t("login:text_2")}</FormLabel>
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="email@email.com"
                    iconLeft={<MailIcon fill="#FFFFFF" />}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="password"> {t("login:text_3")}</FormLabel>
                  <PasswordInput
                    id="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder="********"
                  />
                </FormControl>
                <Stack spacing={4}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  >
                    <Link as={RouterLink} to="/forgot_password" variant="brand">
                      {t("login:text_5")}
                    </Link>
                  </Stack>
                  <Button
                    bg="brand"
                    color="island"
                    type="submit"
                    fontSize={14}
                    disabled={isLoading}
                  >
                    {isLoading ? <Spinner /> : t("login:button_1")}
                  </Button>

                  <Text textAlign="center" color="white">
                    {t("login:text_4:part_1")}{" "}
                    <Link variant="brand" as={RouterLink} to="/signup">
                      {t("login:text_4:part_2")}
                    </Link>
                  </Text>
                </Stack>
              </Stack>
            </form>
          </Stack>
        </Flex>

        <Text color="white" fontSize={12}>
          © 2023 Hodlbot
        </Text>
      </Flex>

      <Flex display={{ base: "none", md: "flex", lg: "contents" }}>
        <img src="login.png" />
      </Flex>
    </Flex>
  );
};

export default Login;
