import { UserType } from "@directus/sdk";
import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { directus } from "../api/directus";

type SessionCtxType = {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  refreshToken: string | null;
  setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  user: UserType | null;
  fetchUser: () => Promise<void>;
};

export const SessionCtx = createContext<SessionCtxType>(undefined as never);

const useSessionContext = () => {
  const [token, setToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserType | null>(null);

  const login = useCallback(async (email: string, password: string) => {
    const response = await directus.auth.login({ email, password });
    const user = (await directus.users.me.read()) as UserType;

    setUser(user);
    setToken(response.access_token);
    setRefreshToken(response?.refresh_token ?? null);
  }, []);

  const logout = useCallback(async () => {
    await directus.auth.logout();
    setUser(null);
    setToken(null);
    setRefreshToken(null);
  }, []);

  const fetchUser = async () => {
    const user = (await directus.users.me.read()) as UserType;
    setUser(user);
  };

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, []);

  // useEffect(() => {
  //   console.log("user", user);
  // }, [user]);

  const ctx: SessionCtxType = useMemo(
    () => ({
      token,
      setToken,
      refreshToken,
      setRefreshToken,
      login,
      logout,
      user,
      fetchUser,
    }),
    [token, setToken, refreshToken, setRefreshToken, login, logout, user]
  );

  return ctx;
};

interface Props {
  children: React.ReactNode;
}

export const SessionContextProvider: FC<Props> = ({ children }) => {
  const ctx = useSessionContext();

  return <SessionCtx.Provider value={ctx}>{children}</SessionCtx.Provider>;
};
