import {
  Box,
  Text,
  Stack,
  List,
  ListItem,
  ListIcon,
  Divider,
  Flex,
  Icon,
  Button,
} from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Flag, TableRecord } from "../utils/types";
import { BsGraphUp, BsTable } from "react-icons/bs";
import { ReactComponent as CalculatorIcon } from "../icons/calculate.svg";
import { Dispatch, SetStateAction } from "react";

import "./PortfolioCardStyles.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = {
  item: TableRecord;
  setShowContent: Dispatch<
    SetStateAction<
      {
        type: "table" | "graph" | "calculator";
        instanceId: string;
      }[]
    >
  >;
  showContent: {
    type: "table" | "graph" | "calculator";
    instanceId: string;
  }[];
};

export default function PortfolioCard({
  item,
  setShowContent,
  showContent,
}: Props) {
  const { t } = useTranslation();

  console.log("dd", item);

  const navigate = useNavigate();

  const showTable =
    showContent.find(
      (i) => i.instanceId === item.InstanceId && i.type === "table"
    ) !== undefined;

  const showGraph =
    showContent.find(
      (i) => i.instanceId === item.InstanceId && i.type === "graph"
    ) !== undefined;

  const showCalculator =
    showContent.find(
      (i) => i.instanceId === item.InstanceId && i.type === "calculator"
    ) !== undefined;

  return (
    <div className="flip-card">
      <div
        className="flip-card-inner"
        style={{
          transform: showCalculator ? "rotateY(180deg)" : "rotateY(0deg)",
        }}
      >
        <div className="filp-card-front">
          <Box minW={280} w="full" bg="bg" rounded={"md"}>
            <Stack textAlign={"center"} p={6} align={"center"}>
              <Text bg="island" p={2} px={3} color="cyan" rounded={"full"}>
                {item.Exchange}
              </Text>
              <Text fontSize={"3xl"}>{item.Symbol}</Text>
            </Stack>

            <Box bg="bg" px={6} pb={5}>
              <List spacing={3}>
                <ListItem>
                  <Text letterSpacing={1} fontWeight="bold">
                    {t("portfolio:card:instance_id")}:
                  </Text>
                  {item.InstanceName}
                </ListItem>

                <ListItem>
                  <Text letterSpacing={1} fontWeight="bold">
                    {t("portfolio:card:expiration")}:
                  </Text>{" "}
                  {/* {itemDetail?.expire_at} */}
                  TODO: Expiration (missing in response)
                </ListItem>
                <ListItem>
                  <Text letterSpacing={1} fontWeight="bold">
                    {t("portfolio:card:active")}:
                  </Text>{" "}
                  {item.Status === "ACTIVE" ? (
                    <ListIcon as={CheckIcon} color="green.400" />
                  ) : (
                    <ListIcon as={CloseIcon} color="red.400" />
                  )}
                </ListItem>
              </List>
              <Divider mt={5} mb={5} />
              <Flex justifyContent="center">
                <Button
                  bg="brand"
                  color="island"
                  onClick={() => navigate(`${item.Id}`)}
                >
                  {t("portfolio:card:show_detail")}
                </Button>
              </Flex>
            </Box>
          </Box>
        </div>
        {/* <div className="flip-card-back">
          <Box
            w={"330px"}
            bg="bg"
            h="full"
            boxShadow={"2xl"}
            rounded={"md"}
            display="flex"
            flex={1}
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Box bg="bg" px={6} pb={5} pt={10}>
                <List spacing={3}>
                  <ListItem>
                    <Flex width="full" justifyContent="space-around">
                      {" "}
                      <ListItem>
                        <Flex></Flex>
                        <Text letterSpacing={1} fontWeight="bold">
                          Buy:{" "}
                        </Text>{" "}
                        {item.Buy}
                      </ListItem>
                      <ListItem>
                        <Text letterSpacing={1} fontWeight="bold">
                          Sell:{" "}
                        </Text>{" "}
                        {item.Sell}
                      </ListItem>
                    </Flex>
                  </ListItem>

                  <ListItem>
                    <Text letterSpacing={1} fontWeight="bold">
                      Transactions per month:
                    </Text>{" "}
                    {item.TransactionAverage}
                  </ListItem>
                  <ListItem>
                    <Text letterSpacing={1} fontWeight="bold">
                      Profit Crypto:
                    </Text>{" "}
                    {item.TransactionAverage}
                  </ListItem>
                  <ListItem>
                    <Text letterSpacing={1} fontWeight="bold">
                      Profit of portfolio:
                    </Text>{" "}
                    {item.TransactionAverage}
                  </ListItem>
                </List>
              </Box>
            </Box>

            <Box px={5}>
              <Divider my={5} />
              <Flex justifyContent="space-between" mb={5}>
                <Icon
                  fill={showGraph ? "cyan" : "#fff"}
                  as={BsGraphUp}
                  fontSize="2xl"
                  m={1}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowContent((prev) => {
                      if (showGraph) {
                        return prev.filter(
                          (i) =>
                            i.instanceId !== item.InstanceId ||
                            i.type !== "graph"
                        );
                      } else {
                        return [
                          ...prev,
                          { instanceId: item.InstanceId, type: "graph" },
                        ];
                      }
                    });
                  }}
                />
                <Icon
                  color={showTable ? "cyan" : "#fff"}
                  as={BsTable}
                  fontSize="2xl"
                  m={1}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowContent((prev) => {
                      if (showTable) {
                        return prev.filter(
                          (i) =>
                            i.instanceId !== item.InstanceId ||
                            i.type !== "table"
                        );
                      } else {
                        return [
                          ...prev,
                          { instanceId: item.InstanceId, type: "table" },
                        ];
                      }
                    });
                  }}
                />
                <CalculatorIcon
                  fill={showCalculator ? "cyan" : "#fff"}
                  width="31"
                  height="31"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowContent((prev) => {
                      if (showCalculator) {
                        return prev.filter(
                          (i) =>
                            i.instanceId !== item.InstanceId ||
                            i.type !== "calculator"
                        );
                      } else {
                        return [
                          ...prev,
                          { instanceId: item.InstanceId, type: "calculator" },
                        ];
                      }
                    });
                  }}
                />
              </Flex>
            </Box>
          </Box>
        </div> */}
      </div>
    </div>
  );
}
