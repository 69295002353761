import { Flex, Heading, Text } from "@chakra-ui/react";
import Island from "../components/Island";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AffilTerms = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <Island>
        <Flex flexDir="column" mb={6} w="full">
          <Heading
            color="white"
            fontSize={"4xl"}
            alignContent="center"
            textAlign="center"
          >
            {t("affiliate:title")}
          </Heading>
          <Text mt={8} color="white" fontSize={"lg"}>
            <span style={{ color: "#50C8FC" }}>{t("affiliate:text_1")}</span>
            <br /> <br />
            {t("affiliate:text_2")}
            <br /> <br />
            {t("affiliate:text_3:part_1")}{" "}
            <span style={{ color: "#50C8FC" }}>
              {t("affiliate:text_3:part_2")}
            </span>
            . {t("affiliate:text_3:part_3")}
            <br /> <br />
            {t("affiliate:text_4")}
            <br /> <br />
            {t("affiliate:text_5:part_1")}{" "}
            <span style={{ color: "#50C8FC" }}>
              {" "}
              {t("affiliate:text_5:part_2")}{" "}
            </span>{" "}
            {t("affiliate:text_5:part_3")}
            <br /> <br />
            {t("affiliate:text_6:part_1")}{" "}
            <span
              onClick={() => navigate("/contacts")}
              style={{
                color: "#50C8FC",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              {t("affiliate:text_6:part_2")}
            </span>
          </Text>
        </Flex>
      </Island>
    </>
  );
};

export default AffilTerms;
