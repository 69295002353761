import { Text, Flex, FlexProps, Button } from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";

type Props = {
  image: string;
  text: string;
  name: string;
  site: string;
  link: string;
  date: string;
} & FlexProps;

const Article = ({ site, image, text, name, link, date }: Props) => {
  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
      m={4}
      bg="bg"
      borderRadius="10px"
      h="full"
    >
      <img src={image} alt="article" style={{ borderRadius: 10 }} />
      <Flex
        flexDir="column"
        alignItems="flex-start"
        w="100%"
        p={4}
        h="full"
        justifyContent="space-between"
      >
        <Flex flexDir="column">
          <Text color="brand">{site}</Text>
          <Text mb={1} mt={1} fontSize={16} fontWeight="bold">
            {name}
          </Text>
        </Flex>
        <Text mt={0} fontSize={14} fontWeight={400}>
          {text.length > 0 ? text : "Bez popisu"}
        </Text>
        <Text mb={1} mt={2} fontSize={14} color="">
          <CalendarIcon boxSize={3} mb={1}></CalendarIcon> {date}
        </Text>
        <Button mt={2} bg="brand" onClick={() => window.open(link, "_blank")}>
          <Text fontSize={14} color="#14142A">
            {link.includes("youtube") ? "Zobrazit video" : "Zobrazit článek"}
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default Article;
