import { useQuery } from "@tanstack/react-query";
import { directus } from "../directus";

export const useDcaDetail = (id: string) =>
  useQuery({
    queryKey: ["instanceDetail", id, directus.auth.token],
    queryFn: async () => {
      await directus.auth.refreshIfExpired();
      const token = await directus.auth.token;

      const res = await fetch(
        `https://api.botcalculator.com/v1/dca/dca_instance/detail?state_id=${id}`,
        {
          headers: {
            AuthToken: token || "",
          },
        }
      );

      const json = await res.json();

      return json;
    },
    enabled: id !== "create",
  });
