import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ReactComponent as StopIcon } from "../icons/stop.svg";
import { ReactComponent as PlayIcon } from "../icons/play_arrow.svg";
import { ReactComponent as PauseIcon } from "../icons/pause.svg";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  current?: string;
  onSelect: (val: string) => void;
};

export default function Select({ onSelect, current }: Props) {
  const [selected, setSelected] = useState(current ?? "ACTIVE");
  const { t } = useTranslation();

  const stateTransform = (state: string, selected?: boolean) => {
    switch (state) {
      case "ACTIVE":
        return (
          <Text
            color="#A6F787"
            display="flex"
            alignItems="center"
            gap={3}
            whiteSpace="nowrap"
          >
            <PlayIcon fill="#A6F787" /> {t("dca:active")}
          </Text>
        );
      case "INSUFFICIENT_BALANCE":
        return (
          <Text
            color="#FFA65A"
            display="flex"
            alignItems="center"
            gap={3}
            whiteSpace="nowrap"
          >
            <StopIcon fill="#FFA65A" /> {t("dca:insufficient_balance")}
          </Text>
        );
      case "STOPPED":
        return (
          <Text
            color="#FF75CB"
            display="flex"
            alignItems="center"
            gap={3}
            whiteSpace="nowrap"
            className="w-full"
          >
            <PauseIcon fill="#FF75CB" width={10} /> {t("dca:stopped")}
          </Text>
        );
      case "LIMIT_EXCEEDED":
        return (
          <Text
            color="#FFA65A"
            display="flex"
            alignItems="center"
            gap={3}
            whiteSpace="nowrap"
          >
            <StopIcon fill="#FFA65A" /> {t("dca:limit_exceeded")}
          </Text>
        );
      default:
        return (
          <Text
            color="#A6F787"
            display="flex"
            alignItems="center"
            gap={3}
            whiteSpace="nowrap"
          >
            <PlayIcon fill="#A6F787" /> {t("dca:active")}
          </Text>
        );
    }
  };

  return (
    <Listbox
      value={selected}
      onChange={(val) => {
        setSelected(val);
        onSelect(val);
      }}
    >
      {({ open }) => (
        <>
          <div className="relative mt-2 min-w-[200px]">
            <Listbox.Button className="relative w-full items-center justify-between flex cursor-default rounded-md px-4 py-2 border-[1px] border-[#6E7191]">
              {stateTransform(selected)}
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400 ml-10"
                aria-hidden="true"
              />
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute border-[#6E7191] border-[1px] z-10 mt-1 max-h-56 w-full bg-[#14142A] rounded-md b py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <Listbox.Option
                  disabled={current === "ACTIVE"}
                  className={({ active }) =>
                    classNames(
                      active || current === "ACTIVE"
                        ? "bg-[#262338] text-white"
                        : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-3 pr-9 "
                    )
                  }
                  value="ACTIVE"
                >
                  {({ selected }) => stateTransform("ACTIVE", selected)}
                </Listbox.Option>
                <Listbox.Option
                  disabled={current === "STOPPED"}
                  className={({ active }) =>
                    classNames(
                      active || current === "STOPPED"
                        ? "bg-[#262338] text-white"
                        : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-3 pr-9"
                    )
                  }
                  value="STOPPED"
                >
                  {stateTransform("STOPPED")}
                </Listbox.Option>
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
