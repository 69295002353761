export function formatDateDca(date: Date) {
  const pad = (num: number) => (num < 10 ? "0" + num : num);

  let year = date.getFullYear();
  let month = pad(date.getMonth() + 1); // Months are 0-indexed
  let day = pad(date.getDate());
  let hours = pad(date.getHours());
  let minutes = pad(date.getMinutes());
  let seconds = pad(date.getSeconds());

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export function formatDateDcaDetail(stringDate: string) {
  if (stringDate === "") return "-";

  const pad = (num: number) => (num < 10 ? "0" + num : num);

  const date = new Date(stringDate);

  let year = date.getFullYear();
  let month = pad(date.getMonth() + 1); // Months are 0-indexed
  let day = pad(date.getDate());
  let hours = pad(date.getHours());
  let minutes = pad(date.getMinutes());
  let seconds = pad(date.getSeconds());

  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}

export const dcaBudgetDate = (minutes: number) => {
  const date = new Date();
  var newDateObj = new Date(date.getTime() + minutes * 60000);

  const pad = (num: number) => (num < 10 ? "0" + num : num);

  let year = newDateObj.getFullYear();
  let month = pad(newDateObj.getMonth() + 1); // Months are 0-indexed
  let day = pad(newDateObj.getDate());

  return `${day}.${month}.${year}`;
};

export const getTimeFromDate = (text: string) => {
  const date = new Date(text);

  const pad = (num: number) => (num < 10 ? "0" + num : num);

  let hours = pad(date.getHours());
  let minutes = pad(date.getMinutes());
  let seconds = pad(date.getSeconds());

  return `${hours}:${minutes}:${seconds}`;
};

export const getDateFromDate = (text: string) => {
  const date = new Date(text);

  const pad = (num: number) => (num < 10 ? "0" + num : num);

  let year = date.getFullYear();
  let month = pad(date.getMonth() + 1); // Months are 0-indexed
  let day = pad(date.getDate());

  return `${day}.${month}.${year}`;
};

export const combineDates = (date1: Date, date2: Date) => {
  const year = date1.getFullYear();
  const month = String(date1.getMonth() + 1).padStart(2, "0"); // Months are 0-based; add 1 to get the correct month
  const day = String(date1.getDate()).padStart(2, "0");

  // Extract the time components
  const hours = String(date2.getHours()).padStart(2, "0");
  const minutes = String(date2.getMinutes()).padStart(2, "0");
  const seconds = String(date2.getSeconds()).padStart(2, "0");

  // Combine into the desired format
  const combinedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return combinedDateTime;
};
