export const en = {
  menu: {
    home_page: "Home Page",
    introduction: "Introduction",
    basic_information: "Basic Information",
    sample_portfolio: "Sample portfolio",
    more_informations: "More Informations",
    they_mentioned_us: "They mentioned us",
    pricing: "Pricing",
    my_applications: "My Applications",
    reviews: "Reviews",
    roadmap: "Roadmap",
    profile: "Profile",
    my_statistics: "Portfolio",
    dca: "DCA",
    api_keys: "API Keys",
    applications: "Applications",
    dcaxbotxhodl: "HODL vs HODLBOT",
    calculator: "Calculator",
    statistics: "Statistics",
    backtest: "Backtest",
    service: "Service",
    general_terms: "General Terms",
    contacts: "Contacts",
    logout: "Log out",
    login: "Log in",
    public_section: "Public Section",
  },
  introduction: {
    section_1: {
      title: "Accumulation of Cryptocurrencies Through Mathematics",
      button: "Create an Account",
    },
    section_2: {
      box_1: "Transactions Completed",
      box_2: "Accumulated for Clients",
      from: "from",
    },
    section_3: {
      title: "Basic Information",
      text_1: {
        part_1: "We have designed",
        part_2: "a system that can multiply Bitcoin",
        part_3: " in the long term under certain predefined conditions.",
        part_4:
          "Simply put, it's a system that utilizes price volatility to create more units,",
        part_5:
          "The system performs buying and selling on the spot market from which it always",
        part_6: "accumulates profit in BTC.",
      },
      title_2: "How to Gain Appreciation on a HODL Portfolio?",
      text_2: {
        part_1: "Some coins and tokens can be",
        part_2: "“staked” and “lent”",
        part_3:
          "from which an investor often gains a very satisfying interest rate.",
        part_4: "However, Bitcoin",
        part_5: "doesn’t offer anything similar,",
        part_6:
          "and yet it's predominantly represented in almost every HODL portfolio.",
        part_7: "Hodlbot",
        part_8: " comes up with a solution to this problem in the long term!",
      },
      button: "How it Works",
      title_3: "Why Own Bitcoin",
      text_3: {
        part_1: "1. Its",
        part_2: "independence from government authorities and banks.",
        part_3: "Bitcoin is a decentralized digital currency, allowing",
        part_4: "direct payments",
        part_5:
          "without the need for a middleman and without having to provide personal information.",
        part_6: "2. The total",
        part_7: "amount of Bitcoin",
        part_8: "that will ever exist",
        part_9: "is limited to 21 million.",
        part_10: "This means that Bitcoin is, by principle,",
        part_11: "becoming increasingly lucrative for investors and Hodlers",
        part_12: ", as seen in its long-term price growth.",
      },
      title_4: "Benefits of HODLBOT",
      text_4: {
        part_1: "Multiplies Bitcoin",
        part_2: "through mathematics",
        part_3: "Not influenced by emotions",
        part_4: "works mechanically",
        part_5: "Generates profit on a portfolio that is unused",
        part_6: "Takes advantage of every price volatility",
        part_7: "More than",
        part_8: "two years of history",
        part_9: "Operation",
        part_10: "24/7",
      },
    },
    section_4: {
      title: "Sample Portfolio",
      text_1: {
        part_1:
          "We have launched a sample portfolio, which we have decided to share and present on our",
        part_2: "Discord server.",
        part_3: "Launched",
        part_4:
          "at the beginning of May 2022 at a price of 39,500 USD per BTC.",
        part_5: "The portfolio value was",
        part_6: "1958 USDT and 0,02626 BTC (1038 USD)",
        part_7: "Trading at 1% and the trade volume is 20 USD.",
        part_8: "By May 8, 2023, Hodlbot made over 3614 trades.",
        part_9: "During this period,",
        part_10: "made 1790 so-called double-trades,",
        part_11: "generating",
        part_12: "a profit of 0.01487 BTC.",
        part_13: "Profit in BTC is better than profit in USD in the long term.",
        part_14: "The current",
        part_15: "average purchase price is now 25,200 USD,",
        part_16: "the cost of buying Bitcoin remains the same at",
        part_17:
          "1038 USD, for which 0.02626 BTC was bought at the price of 39,500 USD.",
        part_18: "If the price returns to the initial value, i.e.,",
        part_19: "to 39,500 USD",
        part_20: "the portfolio will be in profit",
        part_21: "by at least 60%.",
        part_22: "Without Hodlbot",
        part_23: "I would be",
        part_24: "just at break-even.",
        part_25: "See all in video.",
        part_26: "Sample Portfolio",
        part_27: "HERE",
      },
    },
    section_5: {
      title: "More Information",
      title_2: "HODL BOT IDEAS",
      text_1: {
        part_1: "Hardcore hodlers",
        part_2:
          "are prepared to hold Bitcoin under any circumstances over a long time frame.",
        part_3: "Wiser hodlers",
        part_4:
          "have a pre-established partial exit plan, realizing, for example, that their investment increased tenfold and would like to “insure” financially, pay off a mortgage, car, or otherwise improve or secure their life. I believe that everyone should think this way about at least part of their hodl. This is precisely how I think about mine.",
        part_5: "With HODLing,",
        part_6:
          "bone should not speculate or try to time the market. It's just about adhering to predetermined levels where to realize sales and where to buy.",
        part_7:
          "But as we know, the reality is often completely different. Most investorsAle jak víme skutečnost je často úplně jiná. Většina investorů",
        part_8: "don't even have a pre-established plan.",
        part_9: "They often",
        part_10: "succumb to emotions,",
        part_11:
          "and few can behave mechanically or emotionless in the market, master their psyche, and",
        part_12: "follow the rules.",
        part_13: "Hodlbot is built so that yo",
        part_14: "define the conditions in advance",
        part_15:
          "– maximum selling levels, and conversely, how low you want to accumulate.",
        part_16:
          "Within this range, Hodlbot uses price volatility and executes",
        part_17: '"double-trades" i.e., Buy – Sell, 24/7.',
        part_18:
          "When the market falls, it buys, and when the market rises, it sells.",
        part_19:
          "It can thus utilize any quick spike and even the slightest market movement.",
      },
      title_3: "HOW THE BOT WORKS?",
      text_2: {
        part_1: "Hodlbot works on a system",
        part_2: "of trading with predefined conditions,",
        part_3: "individually set using a calculator.",
        part_4: "Your cryptocurrencies won't leave your account,",
        part_5: "and the vast majority are stored safely in",
        part_6: "the vault.",
        part_7: "Hodlbot connects via",
        part_8: "API keys",
        part_9:
          "to your exchange account, where only a minimal part of the capital is held.",
        part_10:
          "In the API key, you allow the placement of trades in the spot market.",
        part_11: "By deleting the API key, you instantly turn off Hodlbot,",
        part_12:
          "and the bot loses connection, meaning that you, as a client, always have the ability to react instantly. Hodlbot",
        part_13:
          "does not trade on leverage nor does it trade any derivatives (CFD).",
        part_17: "We will help you with everything.",
      },
      title_4: "Benefity HODLBOTA",
      text_4: {
        part_1: "Množí Bitcoin",
        part_2: "Multiplies Bitcoin through mathematics",
        part_3: "Not influenced by emotions",
        part_4: ", works mechanically",
        part_5: "Generates profit on a portfolio that is unused",
        part_6: "Takes advantage of every price volatility",
        part_7: "More than",
        part_8: "two years of history",
        part_9: "Operation",
        part_10: "24/7",
      },
    },
    section_6: {
      title: "Mentions",
      text: {
        part_1: "HODLBOT - App for Every Bitcoin HODLer | GUEST: Tomáš Velek",
        part_2: "Discussion and Introduction of HODL Bot",
        part_3: "19.5.2023",
        part_4: "View Video",
        part_5: "HODL, but Smartly with Hodlbot!",
        part_6:
          "This is a slogan under which everyone can imagine something different. I imagine rewards from hodling Bitcoin!",
        part_7: "19.5.2023",
        part_8: "View Article",
        part_9:
          "Sample Portfolio - Reducing the Average Purchase Price by $ 15,000",
        part_10:
          "It is an automated mathematical system built to take advantage of Bitcoin's volatility.",
        part_11: "10.5.2023",
        part_12: "View Video",
        part_13: "View More (4)",
        part_14: "How to Gain Something Extra from HODL Portfolio?",
        part_15:
          "Perhaps a question you're asking yourself because your HODL is often a significant part of your crypto portfolio. Some coins and tokens can be...",
        part_16: "11.11.2022",
        part_17: "View Article",
        part_18: "How to Reduce the Average Buy?",
        part_19:
          "Most people look for a way to use their hodl more than just keeping it in the vault. Various liquidity pools or staking are available.",
        part_21: "9.2.2023",
        part_22: "View Article",
        part_23: "Hodlbot Sample Portfolio",
        part_24:
          "After the last article regarding Hodlbot, I received a few recurring questions, namely: How much does the bot earn or how much capital is needed to start.",
        part_25: "2.6.2022",
        part_26: "View Article",
        part_27: "Passive Income from HODLing Bitcoin",
        part_28:
          "Hardcore hodlers are prepared to hold Bitcoin under any circumstances with a high time frame. Softer hodlers have a predetermined partial exit plan...",
        part_29: "28.4.2022",
        part_30: "View Article",
        part_31: "View Less",
      },
    },
    section_7: {
      title: "Roadmap",
      title_2: "Completed",
      text: {
        part_1: "Q2 2021",
        part_2: "Design and Research of Strategy",
        part_3: "Q2 2021",
        part_4: "Backtesting of Strategy",
        part_5: "Q3 2021",
        part_6: "Launch of Bot Prototype",
        part_7: "Q4 2021",
        part_8: "Closed Testing for First Interested Parties",
        part_9: "Q1 2022",
        part_10: "Data Visualization into Graphs",
        part_11: "Q1 2022",
        part_12: "Integration of User Statistics",
        part_13: "Q2 2022",
        part_14: "Connection to Phemex.com Exchange",
        part_15: "Q2 2022",
        part_16: "Integration of Fractional Balance on Exchange",
        part_17: "Q3 2022",
        part_18: "Email Notifications about Portfolio Status",
        part_19: "Q1 2023",
        part_20: "New Backtests until Q1 2023",
        part_21: "Q2 2023",
        part_22: "New Websites",
      },
      title_3: "What Awaits Us?",
      text_2: {
        part_1: "Q2 2023",
        part_2: "Launching Affiliate Program",
        part_3: "Q2 2023",
        part_4: "Launching English Version",
      },
      title_4: "Long-Term Goals",
      text_3: {
        part_1: "Q2 2022",
        part_2: "Launching DCA Strategy",
        part_3: "Q2 2023",
        part_4: "Interface Strategy on Stocks and Commodities",
        part_5: "Q2 2023",
        part_6: "Implementation of Payment Gateway",
        part_7: "Q2 2023",
        part_8: "Implementation of Rebalancing Strategy",
        part_9: "Q3 2023",
        part_10: "Creating Educational Section",
        part_11: "Q3 2023",
        part_12: "Implementing More Cryptocurrency Exchanges",
      },
    },
    section_8: {
      title: "Partners",
    },
  },
  intro_information: `
    Algo Traders Ltd. (limited liability company), ID No.: 19194536, registered office: Hoštičky 39, 33901 Klatovy, (hereinafter referred to as the "Company"), hereby provides information on the manner and extent of processing of personal data of its clients (hereinafter referred to as the "Client"), including the rights of Clients related to the processing of personal data (hereinafter referred to as the "Policies"), in accordance with Act No. 110/2019 Coll., on the processing of personal data, and Regulation (EU) 2016/679.
    `,
  data_processing_policies: `
    Data Controller and Data Processing Method
    The Company, as the data controller, collects, stores, and uses personal data in connection with its business activities, and these Policies have been created to ensure the proper management of such data.

    The processing and storage of personal data take place at the Company's registered office and are carried out exclusively by the Company's employees unless these Policies specify otherwise. The processing of personal data is carried out both manually and automatically in electronic information systems, in electronic form, always with a high level of technical, organizational, and personnel security in accordance with the requirements of relevant legislation.

    If the Client uses the Company's services, the Company may use the Client's contact details to send the Client business communications about services that may be of interest to the Client. The Client can unsubscribe from receiving business communications at any time by sending a message to info@hodlbot.cz.

    Purpose of Personal Data Processing
    The Company acts as the data controller when processing personal data and determines the purpose and means of processing personal data. Personal data are processed either: (i) without any further registration for processing, based on the Client's request for information, or (ii) based on the Client's consent provided to the Company.
    
    Retention Period of Personal Data
    The Company retains the personal data of Clients for a period of 10 years from the date of acquisition, unless the Client withdraws their consent to the processing of personal data earlier. 
    
    The Client has the right to request:
    - access to their personal data,
    - rectification of personal data,
    - erasure of personal data,
    - restriction of personal data processing,
    - data portability,
    - to object to the processing of personal data.
  `,
  hodl_x_hodlbot: {
    title: "HODL x HODLBOT",
    text_1:
      "HODL is slang for a strategy of holding cryptocurrencies or other assets instead of selling, even when the price is falling. In cryptocurrencies, a Hodler is often someone who holds and does not sell when the price drops, and even when the price rises. Consequently, if the price rises from their purchase and then falls back to where they bought, they realize no profit. They likely only experience stress.",
    text_2:
      'Our proposed Hodlbot system is an "alternative" to previous strategies and brings many advantages and possibilities.',
    text_3:
      "Thanks to the volatility of the asset, in our case Bitcoin, Hodlbot's system multiplies further units of Bitcoin. Everything works on sequential mathematics, not a trading strategy composed of indicators. You also set the price in advance, up to when you want to buy Bitcoin and when you want to gradually sell it. This removes all the burden of decision-making, and you are at ease at the moment when the price is soaring up or down.",
    title_2: "HODL X HODLBOT",
    text_4:
      "first, watch the video to understand how the system works. Why does it make sense for every HODLer to use Hodlbot?",
    text_5:
      "Investors Pepa and Jirka invest in Bitcoin. Pepa is purely a Hodler, while Jirka allocates his portfolio to Hodlbot.",
    text_6:
      "Each has a completely different result after 2 and a quarter years.",
    text_7: "Two things connect Pepa and Jirka:",
    text_8:
      "Both invested at the same time and bought the same amount of Bitcoin.",
    text_9: "Both did nothing the whole time and kept their feet warm.",
    text_10:
      "Pepa and Jirka bought Bitcoin on 1/1/2021 for $5,920 at a price of $28,500. So each has 0.2077 BTC. Pepa only hodls his Bitcoin, while Jirka allocates his Bitcoin to Hodlbot management, with the setting that Jirka's 0.2077 BTC would be gradually sold up to $179,000 per Bitcoin. As of 3/31/2023, i.e., after two and a quarter years, Bitcoin was priced at $28,500. Paradoxically, exactly the same as at the beginning. Pepa still had his same 0.2077 BTC with the same dollar value as he invested and the same average purchase price. Along the way, he experienced a roller coaster ride, and in the end, he gained nothing from it but psychological damage. Jirka had his 0.2077 BTC from the start plus units accumulated by Hodlbot from volatility. He accumulated 0.12497 BTC. Thus, Jirka had a total of 0.33269 BTC with a dollar value of $9,481, but with an average purchase price of $17,794.",
    text_11: "For a more detailed description of the process – click to expand",
    text_12:
      "Jirka also calculated a potential allocation of $4,000 in the event of the price dropping below his purchase price. (explanation is in the video)",
    text_13:
      "Half a year after launch, i.e., on 1.7.2021, Bitcoin was at $33,400. Pepa still had the same balance of 0.2077 BTC, worth $6,937. By that time, Hodlbot had accumulated 0.04924 BTC for Jirka. After adding what was left, he had a total of 0.22079 BTC. Jirka's Bitcoin was therefore worth $7,374, considering that he had already exited some Bitcoin since the price was higher than at the beginning, specifically he had $1,050 in free sales. As Jirka accumulated more free Bitcoin, his average purchase price dropped to $23,040. One year after launch, i.e., on 1.1.2022, Bitcoin was at $47,340. Pepa's 0.2077 Bitcoin was worth $9,832. Jirka had 0.17426 BTC, worth $8,249. In addition, he had already exited capital of $3,570. Jirka's average purchase price was $21,760.",
    text_14:
      "One and a half years after launch, i.e., on 1.7.2022, Bitcoin was at $19,333. Pepa's 0.2077 Bitcoin was worth $4,015. Jirka had 0.41707 BTC, having more Bitcoin as the price had dropped below his starting price, his net Bitcoin value is 0.2077 (start) + his profit which was 0.29935 BTC. Only his purchased and earned Bitcoin was worth $5,787. His average purchase price was then $19,776.",
    text_15:
      "Two years after launch, i.e., on 1.1.2023, Bitcoin was at $16,488. Pepa's 0.2077 BTC was worth $3,424, and most investors, even though they want to hold, don't feel comfortable being in a significant loss position, especially after having been in a big profit. Jirka had 0.50342, having more Bitcoin as the price was still below his starting price. Initial Bitcoin balance and profits in Bitcoin together were 0.32255 BTC. The average purchase price was therefore $18,353.",
    text_16:
      "As of 31.3.2023, i.e., after two and a quarter years, Bitcoin was at $28,500. Paradoxically, exactly the same as at the beginning.",
    text_17:
      "Pepa still had his same 0.2077 BTC with the same dollar value he invested and therefore the same average purchase price. Along the way, he endured a rollercoaster ride, and in the end, he has nothing from it but psychological harm.",
    text_18:
      "Jirka had his initial 0.2077 BTC plus units accumulated by Hodlbot from volatility. He accumulated 0.12497 BTC. Jirka therefore had a total of 0.33269 BTC with a dollar value of $9,481, but with an average purchase price of $17,794.",
  },
  contacts: {
    title: "Contact Form",
    text_1: "Full Name",
    text_2: "Email",
    text_3: "Question",
    text_4: "Send question",
    title_2: "Join the Community",
    text_5:
      "Here you can ask people who have already tested or are testing the bot. Additionally, questions from others are answered here.",
    text_6: "There is also a sample portfolio presented on the server.",
    title_3: "Contact Details",
  },
  affiliate: {
    title: "Affiliate program",
    text_1: "Spread the Good Idea!",
    text_2:
      "If you like this project, we'll be glad if you share it among your friends whom you think might be interested in Hodlbot, but especially if it could be beneficial for them.",
    text_3: {
      part_1: "For these reasons, we're launching this",
      part_2: "affiliate program",
      part_3:
        "To be eligible for a reward within the program, the client you referred must register with your referral code, which you can generate on this platform. Furthermore, the referred client must launch our Hodlbot. Our team will assist with the setup of an appropriate portfolio and the actual launch, or explain and clarify any uncertainties.",
    },
    text_4: `If the client completes and subsequently pays the first invoice, i.e., for the first paid month. (Note: it may occur that during some promotions, the use of Hodlbot may be free for a certain period of time, so you are not entitled to a reward for this period)`,
    text_5: {
      part_1: "The reward is",
      part_2: "50% of the 1st payment ",
      part_3:
        "(i.e., the first paid month). We will either pay out the reward or deduct it from the commission you would pay us for your launched Hodlbot.",
    },
    text_6: {
      part_1:
        "All of this applies unless there is another individual agreement between you and us (Algo Traders Ltd.). If you are interested in individual terms within the affiliate, you can contact us via the",
      part_2: "contact form.",
    },
    checkbox: {
      part_1: "I agree with the",
      part_2: "terms of the affiliate program",
    },
    message: {
      success: "Promo code was generated",
      error: 'Error: "Promo code" was not generated, please contact us.',
      copy: "Odkaz byl zkopírován do schránky",
    },
    button: "Generate Promo code",
    table: {
      text_1: "Promo code ",
      text_2: "NUMBER OF REG ",
      text_3: "NUMBER OF ACTIVE",
      text_4: "PAID (CZK)",
      text_5: "NOT PAID (CZK)",
      text_6: "LINK",
      text_7: "Total",
    },
  },
  api_keys: {
    title: "API keys",
    text_1: "Api key",
    text_2: "Secret key",
    text_3:
      "By recreating, the existing api keys for the given exchange will be overwritten.",
    button: "Update / create api keys ",
    text_4: "You don't have any api keys yet",
    button_1: "Add api key",
    title_1: "Choose the exchange where you want to trade.",
    binance:
      "Binance is one of the leading cryptocurrency exchanges, founded in 2017. It offers trading with a variety of cryptocurrencies and stands out with its token currency, Binance Coin (BNB), which is used to reduce fees and other functions on the platform. It's suitable for large portfolios due to high liquidity and the lowest fees, which can be only 0.065%.",
    phemex:
      "Phemex is a fast-growing cryptocurrency exchange that was established in 2019. It offers trading with various cryptocurrencies and is known for its user-friendly platform and competitive fees. It is an ideal exchange for smaller portfolios, with comparable fees to other exchanges. The advantage is that it trades each instrument on more decimal places than other exchanges.",
    text_5: {
      part_1: "I know how to proceed because I saw",
      part_2: "a video tutorial",
      binance: "for Binance.",
      phemex: "for Phemex.",
      bybit: "for Bybit.",
      coinmate: "for Coinmate.",
    },
    title_2: {
      part_1: "Enter the api key and secret key for the ",
      binance: "BINANCE exchange.",
      phemex: "PHEMEX exchange.",
      bybit: "BYBIT exchange.",
      coinmate: "COINMATE exchange.",
    },
    text_6: "I've set permissions to read & trading correctly.",
    button_2: "Save",
    button_3: "Back",
    title_3: "About api keys ",
    text_7:
      "If you haven't created an account on the exchange of your choice, continue by clicking on the exchange logo.",
    text_8: "If anything is unclear, below is a video tutorial.",
    notif_success: "The api key has been uploaded.",
    notif_error: "The api key has failed.",
    text_9: {
      part_1: "I want to trade on",
      binance: "Binance",
      phemex: "Phemex",
      bybit: "Bybit",
      coinmate: "Coinmate",
    },
    text_10: "Connect your Binance account",
    text_11: "Connect your Phemex account",
    text_12: "Create a new Binance account",
    text_13: "Create a new Phemex account",
    text_14: "Connect your Bybit account",
    text_15: "Create a new Bybit account",
    text_16: "Create a new Coinmate account",
    text_17: "Connect your Coinmate account",
    bybit:
      "Bybit, established in 2018, is known for its extensive portfolio of cryptocurrency services, including spot trading. This platform offers trading with a wide range of cryptocurrencies and is popular for its intuitive user interface and efficient customer support. The fees for spot trading on Bybit are at 0.1%. It is suitable for large portfolios.",
    coinmate:
      "Coinmate, established in 2014, is recognized for its spot trading services in cryptocurrencies. This exchange focuses on European markets and offers trading in several major cryptocurrencies. It is known for its user-friendly interface and quick customer support. The fees for spot trading on Coinmate are 0.3%. This exchange is suitable for anyone who prefers a European, even Czech, company.",
    text_18: "Client id",
    not_connected: "State: Not connected",
  },
  portfolio: {
    title: "Portfolio",
    no_statistics: "You currently have no statistics.",
    show_more: "Show more",
    show_less: "Show less",
    card: {
      instance_id: "Instance ID",
      expiration: "Expiration",
      active: "Active",
      show_detail: "Show detail",
    },
    instance_detail: {
      title: "Instance Detail",
      no_rights: "You do not have the rights to view instance details.",
    },
  },
  calculator: {
    traded_pair: "Traded Pair",
    information_1: "e.g.: BTC/USDT",
    jump: "% Jump",
    information_2:
      "Enter the desired percentage spread between individual trading levels. Recommended 1% - 2%, but it depends on portfolio size. The smaller the percentage, the more trades, but a smaller profit from a single buy-sell pair.",
    exchange: "Exchange",
    information_3: "To how many decimal places does the exchange round?",
    trade_volume: "Trade Volume",
    information_4: "value of 20 in the BTCUSDT pair = 20 USDT",
    fee_in: "Fee in %",
    information_5:
      "How large is the fee for a trade on the spot market at your exchange?",
    capital: "Capital",
    information_6:
      "Total capital size, i.e., the sum of values in FIAT and the dollar value of cryptocurrency at the price you entered.",
    guide_to_hodlbot_part_1: "Guide to",
    guide_to_hodlbot_part_2: "Hodlbot",
    guide_to_hodlbot_part_3: "and",
    guide_to_hodlbot_part_4: "Calculator",
    guide_to_hodlbot_part_5: "here",
    guide_to_hodlbot_part_6: "for calculator",

    index: "INDEX",
    percentages: "PERCENTAGES",
    price: "PRICE (EXCHANGE RATE)",
    information_7:
      "Enter the price of the cryptocurrency from which you want Hodlbot to calculate. This is usually the current price.",
    fiat: "FIAT",
    information_8:
      "Enter the fiat value you want to allocate to Hodlbot. FIAT affects the minimum buy value.",
    crypt_balance: "CRYPTO BALANCE",
    crypto_profit: "CRYPTO PROFIT",
    fiat_profit: "FIAT PROFIT",
    init_price: "INIT PRICE",
  },
  backtest: {
    statistics: "statistics",
    graphs: "graphs",
  },
  statistics: {
    statistics: "statistics",
    graphs: "graphs",
    active: "active",
    all: "all",
    pending: "pending",
    ended: "ended",
  },
  profile: {
    notif_success: "Billing details have been successfully saved",
    notif_error: "Unknown error",
    title: "Billing Details",
    text_1: "Please enter your billing details",
    text_2: "Personal Information",
    text_3: "First Name",
    text_4: "Last Name",
    text_5: "Email Address",
    text_6: "Phone Number (optional)",
    text_7: "Billing Address",
    text_8: "Street and Number",
    text_9: "City",
    text_10: "Postal Code",
    text_11: "Country",
    button_1: "Save",
    button_2: "Reset Password",
    text_12: "I want to receive email notifications",
    text_13: "Marketing communications, e.g., 'News, events, discounts'",
  },
  login: {
    title: "Login",
    text_1: "Please enter your login credentials",
    text_2: "Email address",
    text_3: "Password",
    button_1: "Log in",
    text_4: {
      part_1: "Don't have an account? ",
      part_2: "Register here",
    },
    text_5: "Forgot your password?",
    notif_error: "Failed to log in, please check your login credentials.",
  },
  register: {
    required: "Required field",
    wrong_email: "Wrong email format",
    not_match: "Passwords do not match",
    must_agree: "You must agree",
    title: "Registration",
    text_1: "Please fill in the following details",
    text_2: "First Name",
    text_3: "Enter your first name",
    text_4: "Last Name",
    text_5: "Enter your last name",
    text_6: "Email Address",
    text_7: "Enter your email address",
    text_8: "Password",
    text_9: "Enter password",
    text_10: "Confirm Password",
    text_11: "Re-enter your password",
    text_12: "Promo code",
    text_13: {
      part_1: "I agree with the ",
      part_2: "terms and conditions",
    },
    button_1: "Register",
    errors: {
      "1": "An account with the same email already exists",
      "2": "Unknown error",
    },
    success: "Successfully registered",
  },
  pricing: {
    text_1: {
      part_1: "Hodlbot is a service that",
      part_2: " generates profit for the client in cryptocurrency,",
      part_3: " most commonly Bitcoin is chosen. The service is charged",
      part_4: " a 15% fee based on the profit.",
      part_5:
        " At the end of the agreed-upon period (usually monthly or quarterly),",
      part_6:
        " the profit in cryptocurrency is calculated, and 15% of that is the commission for Hodlbot's services.",
    },
    text_2:
      "Most clients pay the commission in FIAT currency (CZK, EUR, USD). This means that the commission in cryptocurrency is converted into the desired currency at the exchange rate at that moment.",
    text_3: "Example of calculating the commission for the 2nd quarter.",
    text_4: {
      part_1: "On July 1st,",
      part_2: "the cryptocurrency price is 100,000 CZK.",
      part_3: "July 1st - April 1st = 2nd quarter.",
      part_4: "0.012 - 0.06 =",
      part_5: "0.06",
      part_6: "Profit in cryptocurrency",
      part_7: "* 15% =",
      part_8: "0.009",
      part_9: "Commission in cryptocurrency",
      part_10: "0.009",
      part_11: "* 100,000",
      part_12: "900 CZK",
      part_13: "Commission in CZK",
      part_14: "Gross profit in CZK",
      part_15: "Net profit in CZK",
    },
  },
  dca_create: {
    title: "Create DCA Instance",
    title_update: "Edit DCA Instance",
    subtitle:
      "Dollar Cost Averaging (DCA), also known as the strategy of averaging dollar costs, aims to reduce the impact of volatility when purchasing assets. This strategy involves buying individual assets or a portfolio of assets for the same amount in fiat money at regular time intervals.",
    name_title: "Name Your Instance",
    name_placeholder: "E.g.: Better Future",
    name_info:
      "This name will help you stay the course to your financial goals and remind you why you are investing regularly – whether it's 'Retirement Fund' or 'Children's Education'.",
    name_api_key: "API Key",
    api_key_title: "Linking to the Exchange",
    api_key_info:
      "Enter your API key to connect to your investment platform. This will allow you to set up and automate your DCA strategy to match your investment goals and preferences.",
    api_key_placeholder: "Select an API key",
    name_pair: "Tradable Pair",
    pair_info:
      "Enter the tradable pair you want to regularly purchase. For example, for investing in Bitcoin using the US dollar, enter 'BTC/USDT'",
    pair_placeholder: "E.g.: BTC-USDT",
    pair_disabled_placeholder: "Select an API key first",
    name_amount: "How much do you want to purchase?",
    amount_info: "Enter the amount you want to invest regularly.",
    amount_text_1: "Purchase for",
    amount_text_2: "Currently on the exchange",
    amount_text_3: "Available for {{amount}} purchases",
    name_interval: "How often do you want to make purchases?",
    interval_text: "Every",
    interval_info:
      "Choose the regularity of purchases for your DCA strategy, from several minutes to weeks, and maintain a disciplined approach to investing.",
    name_first_buy: "Initial Purchase",
    first_buy_info:
      "Set the date and time when your DCA strategy should start. The strategy will automatically begin making purchases according to your schedule from the specified date and time and will continue until you have exhausted the allocated capital.",
    first_buy_text: "Capital will last until",
    name_special: "Dynamic Purchases Deviation Below Average Price",
    special_text_1: "If the current price is lower than the purchase price by",
    special_info:
      "By using this feature, you dynamically adjust the purchase volume based on the current market price. If the market price is lower than the current average purchase price, the system automatically increases the purchase amount, thus averaging down the costs. This can help you reduce the overall average price of your investments but may also deplete your allocated capital more quickly.",
    special_text_2: ", then increase the purchase amount by",
    name_price_border: "Price Limit",
    special_button: "+ Add Another Rule",
    price_border_text_1: "If the price {{unit}} is above",
    price_border_text_2: ", then adjust the purchase amount by",
    price_border_text_3: "multiplier.",
    price_border_info:
      "Price limiting allows you to set a value, upon exceeding which, the volume of your purchase is automatically reduced. For example, if you set a limit at 50,000 USDT and the market price of Bitcoin rises above this value, the system adjusts the purchase volume down. This helps to avoid buying at high prices and increases the efficiency of your strategy by maintaining purchases at more favorable price points.",
    price_border_special_info:
      "When the price is higher than {{limit}} {{unit}}, then purchase for {{ratio}} {{unit}}.",
    submit: "Create Instance",
    minutes: "Minutes",
    hours: "Hours",
    days: "Days",
    weeks: "Weeks",
    months: "Months",
    no_api_keys: "You need to have stored API keys to create a DCA instance.",
    no_api_keys_link: "+ Create API Key",
    update: "Edit Instance",
    soon: "Soon",
    amount: "Amount",
    ratio: "Multiplier",
    capital_restriction: "Capital Restriction",
    capital_restriction_text: "Allocate for purchases",
    capital_restriction_text_2: "stop strategy after exceeding amount.",
    capital_restriction_info:
      "Enter the maximum sum you want to invest in your DCA strategy. This amount represents the upper limit of the total capital.",
    dynamic_strategy: "Dynamic NUPL Strategy",
    more_info: "More information on the upcoming strategy",
    here: "here",
    dynamic_strategy_under_name:
      "Dynamic Purchases Deviation Above Average Price",
    optional: "Optional",
  },
  dca: {
    title: "DCA",
    active: "Active",
    stopped: "Stopped",
    insufficient_balance: "Insufficient Capital",
    limit_exceeded: "Limit Exceeded",
    minutes: "Minutes",
    hours: "Hours",
    days: "Days",
    weeks: "Weeks",
    months: "Months",
    what_is_dca: "What is DCA?",
    what_is_dca_info:
      "Dollar Cost Averaging (DCA) is an investment strategy where the investor divides the total amount to be invested into regular purchases of the target asset in an effort to reduce the impact of volatility on the overall purchase. Purchases occur regardless of the asset's price and at regular intervals.",
    no_instances: "You do not have any DCA instances",
    no_instances_info:
      "Create your first DCA instance and start investing with it.",
  },
  dca_detail: {
    title: "Instance Information",
    api_key: "API Key",
    pair: "Traded Pair",
    amount: "Purchase For",
    frequency: "Frequency",
    last_buy: "Last Purchase",
    next_buy: "Next Purchase",
    special_buys: "Special Purchases",
    price_border: "Price Limit",
    modal_title: "Do you wish to change the status?",
    modal_text: "Do you wish to change the status from ",
    to: " to ",
    cancel: "Cancel",
    confirm: "Confirm",
    special:
      "If the current price is lower by {{percentage}}%, then increase the purchase unit by {{value}} {{sign}}.",
    price_limit:
      "If the price is above {{limit}}$, then limit the purchase unit by {{ratio}} multiplier",
    transactions_history: "Transaction History",
    number_of_transactions: "Number of Transactions",
    bought: "Purchased",
    show: "Show Transactions",
    amount_trans: "Amount",
    buy: "Buy",
    sell: "Sell",
  },
  online_meeting: {
    title: "Online Meeting",
  },
};
