import { useEffect, useState } from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import Island from "../components/Island";
import { useParams } from "react-router-dom";
import "wysiwyg.css";

const ArticlePage = () => {
  const [article, setArticle] = useState<any>(undefined);
  const params = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<string>("");

  useEffect(() => {
    (async () => {
      const res = await fetch(
        `https://directus.botcalculator.com/items/aa_articles/${params.articleId}`
      );
      const json = await res.json();
      setArticle(json.data);
    })();
  }, [params.articleId]);

  const handleImageClick = (e: any) => {
    if (e.target.tagName === "IMG") {
      setSelectedImage(e.target.src);
      onOpen();
    }
  };

  return (
    <>
      <Island>
        <Text align="center" fontSize="3xl" width="full" mb={10}>
          {article?.title}
        </Text>

        <div className="wysiwyg" onClick={handleImageClick}>
          <div dangerouslySetInnerHTML={{ __html: article?.body }} />
        </div>
      </Island>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <Image src={selectedImage} />
        </ModalContent>
      </Modal>
    </>
  );
};

export default ArticlePage;
