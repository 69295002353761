import { useQuery } from "@tanstack/react-query";

export const usePairs = (apiId?: string) =>
  useQuery({
    queryKey: ["pairs", apiId],
    queryFn: async () => {
      if (!apiId) return null;

      const res = await fetch(
        "https://api.botcalculator.com/admin/exchange/pairs",
        {
          method: "POST",
          body: JSON.stringify({
            api_id: apiId,
          }),
        }
      );

      const json = await res.json();

      return json;
    },
  });
