import { useQuery } from "@tanstack/react-query";
import { directus } from "../directus";

export const useCount = (instanceId: string) =>
  useQuery({
    queryKey: ["count", instanceId],
    queryFn: async () => {
      if (!instanceId) return null;

      await directus.auth.refreshIfExpired();
      const token = await directus.auth.token;

      const res = await fetch(
        `https://api.botcalculator.com/v1/dca/dca_instance/detail/transactions/count?instance_id=${instanceId}`,
        {
          headers: {
            AuthToken: token || "",
          },
          method: "GET",
        }
      );

      const json = await res.json();

      return json;
    },
  });
